import { Spine } from '@pixi/spine-pixi';
import { BitmapText, Graphics, Sprite, Text, Texture } from 'pixi.js';
import { getLocale } from '../../../../../localization';

const TEXT_OFFSET_Y = -200;
const BUTTON_OFFSET_Y = 120;
const TEXT_GAP = 10;
const PRESS_TEXT_Y_OFFSET = 200;
const BUTTON_GAP = 200;

class Popup {
  static popupExists = false;
  container!: Spine;
  okButton!: Sprite;
  blocker!: Graphics;
  pressText!: Text;
  cancelButton!: Sprite;
  texts: { text: Text | BitmapText; marginTop?: number }[] = [];
  sprites: { sprite: Sprite; yOffset?: number }[] = [];

  constructor(
    texts: { label: string; fontFamily?: string; marginTop?: number }[],
    private options: {
      onOk?: () => void;
      onCancel?: () => void;
      onClose?: () => void;
      zIndex?: number;
      sprites?: { sprite: Sprite; yOffset?: number }[];
    }
  ) {
    if (!this.options.zIndex) this.options.zIndex = 6;
    this.texts = texts.map((text, index) => {
      let textObject: Text | BitmapText;
      if (
        text.fontFamily === 'goldenTextFont'
        || text.fontFamily === 'goldenTextFontOld'
        || text.fontFamily === 'symbolOverlayFont'
        || text.fontFamily === 'symbolOverlayFontOld'
      ) {
        textObject = new BitmapText({
          text: text.label,
          style: {
            fontSize: 70,
            fontFamily: text.fontFamily,
          },
        });
        textObject.anchor.set(0.5);
      }
      else {
        textObject = new Text();
        textObject.text = text.label;
        textObject.style = {
          fill: 0xffffff,
          fontSize: 70,
          fontFamily: text.fontFamily || 'ManchoBold',
          dropShadow: true,
        };
        textObject.anchor.set(0.5);
      }
      return { text: textObject, marginTop: text.marginTop };
    });
    this.sprites = options.sprites || [];
    if (!options.onOk && !options.onCancel) {
      const handleGlobalClick = () => {
        this.options.onClose?.();
        this.unMount();
      };
      window.game!.onClickOrPress.addEventListenerOnce(handleGlobalClick);
    }
  }

  handleResize = () => {
    this.container.scale.set(window.game!.scale);
    this.container.x = window.game!.width / 2;
    this.container.y = window.game!.height / 2;
    let textEndY = TEXT_OFFSET_Y;
    this.texts.forEach((el) => {
      el.text.x = 0;
      el.text.y = textEndY + (el.marginTop || 0);
      textEndY = el.text.y + el.text.height + TEXT_GAP;
    });
    this.sprites.forEach((el) => {
      el.sprite.x = 0;
      el.sprite.y = textEndY + (el.yOffset || 0);
      textEndY = el.sprite.y + el.sprite.height + TEXT_GAP;
    });

    if (this.cancelButton) {
      this.cancelButton.x = -150;
      this.cancelButton.y = BUTTON_OFFSET_Y;
    }
    if (this.okButton) {
      this.okButton.x = this.cancelButton ? 150 : 0;
      this.okButton.y = BUTTON_OFFSET_Y;
    }

    if (this.pressText) {
      this.pressText.x = 0;
      this.pressText.y = PRESS_TEXT_Y_OFFSET;
    }

    this.blocker.width = window.game!.width;
    this.blocker.height = window.game!.height;
  };

  mount() {
    if (Popup.popupExists) return;
    Popup.popupExists = true;
    window.game?.betButtonsDisabler.disable('popup');

    // 🛑 Create an Interactive Background Overlay 🛑
    this.blocker = new Graphics();
    this.blocker.beginFill(0x000000, 0.5); // Black overlay with 50% opacity
    this.blocker.drawRect(0, 0, window.game!.width, window.game!.height);
    this.blocker.endFill();
    this.blocker.interactive = true; // 🛑 Prevent clicks behind popup
    this.blocker.zIndex = this.options.zIndex! - 1; // Ensure it's below the popup
    window.game!.app.stage.addChild(this.blocker);

    // 🎨 Create Popup Container
    this.container = Spine.from({ skeleton: 'popupData', atlas: 'popupAtlas' });
    this.container.zIndex = this.options.zIndex!;
    window.game!.app.stage.addChild(this.container);

    this.container.x = window.game!.width / 2;
    this.container.y = window.game!.height / 2;
    this.container.scale.set(window.game!.scale);

    // 🎬 Animate Popup In
    const animation = this.container.state.setAnimation(0, 'start', false);
    animation.listener = {
      complete: () => {
        setTimeout(() => {
          this.container.state.setAnimation(0, 'idle', true);
          this.texts.forEach((item) => {
            item.text.text = item.text.text.toLocaleUpperCase();
            this.container.addChild(item.text);
          });
          this.sprites.forEach((el) => this.container.addChild(el.sprite));
          if (this.options.onOk) this.okButton = this.initOkButton();
          if (this.options.onCancel) this.cancelButton = this.initCancelButton();
          if (!this.options.onOk && !this.options.onCancel) this.pressText = this.initPressText();
          window.game!.onResize.addEventListener(this.handleResize);
          this.handleResize();
        }, 0);
      },
    };
  }

  initCancelButton() {
    const buttonTexture = Texture.from('cancelButton'); // Use any button image
    const button = new Sprite(buttonTexture);
    this.container!.addChild(button);
    button.anchor.set(0.5);
    button.interactive = true;
    button.cursor = 'pointer'; // Set cursor to pointer on hover
    button.width = 120;
    button.height = 120;
    button.x = window.game!.width / 2 - 80;
    button.y = 140;
    button.zIndex = 100;
    button.alpha = 10000;

    button.on('pointerdown', () => {
      button.scale.set(button.scale.x * 0.9);
      button.tint = 0xaaaaaa;
    });
    button.on('pointerup', () => {
      button.scale.set(button.scale.x / 0.9);
      button.tint = 0xffffff;
      this.options.onCancel?.();
      this.unMount();
    });
    button.on('pointerupoutside', () => {
      button.scale.set(button.scale.x / 0.9);
      button.tint = 0xffffff;
    });
    return button;
  }

  initPressText() {
    const text = new Text();
    this.container!.addChild(text);
    text.text = getLocale('slots.ui.dede.pressContinue').toLocaleUpperCase();
    text.anchor.set(0.5);
    text.style = {
      fill: 0xffffff,
      fontSize: 40,
      fontFamily: 'ManchoBold',
      dropShadow: true,
    };

    text.x = window.game!.width / 2;
    text.y = PRESS_TEXT_Y_OFFSET;
    text.zIndex = 100;
    return text;
  }

  initOkButton() {
    const buttonTexture = Texture.from('okButton'); // Use any button image
    const button = new Sprite(buttonTexture);
    this.container!.addChild(button);
    button.anchor.set(0.5);

    button.interactive = true;
    button.cursor = 'pointer'; // Set cursor to pointer on hover
    button.width = 160;
    button.height = 120;
    button.x = window.game!.width / 2 + 30;
    button.y = 140;
    button.zIndex = 100;
    button.alpha = 10000;

    button.on('pointerdown', () => {
      button.scale.set(button.scale.x * 0.9);
      button.tint = 0xaaaaaa;
    });
    button.on('pointerup', () => {
      button.scale.set(button.scale.x / 0.9);
      button.tint = 0xffffff;
      this.options.onOk?.();
      this.unMount();
    });
    button.on('pointerupoutside', () => {
      button.scale.set(button.scale.x / 0.9);
      button.tint = 0xffffff;
    });
    return button;
  }

  unMount() {
    if (this.okButton) this.okButton.destroy();
    if (this.cancelButton) this.cancelButton.destroy();
    if (this.pressText) this.pressText.destroy();
    this.texts.forEach((text) => this.container.removeChild(text.text));
    this.sprites.forEach((el) => this.container.removeChild(el.sprite));

    const animation = this.container.state.setAnimation(0, 'end', false);
    animation.listener = {
      complete: () => {
        setTimeout(() => {
          Popup.popupExists = false;
          window.game?.betButtonsDisabler.enable('popup');

          // 🛑 Remove Background Blocker
          if (this.blocker) {
            this.blocker.destroy();
          }

          this.container?.destroy();
          window.game!.onResize.removeEventListener(this.handleResize);
        }, 0);
      },
    };
  }
}

export default Popup;
