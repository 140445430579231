import { Application, Container } from 'pixi.js';
import { sound } from '@pixi/sound';
import { IGameOutcome } from './dede/service/types';
import {
  AnteBetChangeListener,
  AssetsLoadedListener,
  AutoPlayChangeListener,
  ClickListener,
  OrientationChangeListener,
  ExplodeListener,
  FallCompleteListener,
  FreeSpinChangeListener,
  GameConfigChangeListener,
  GameLoadedListener,
  GameStateChangeListener,
  HistoryChangeListener,
  IFreeSpinStatus,
  IGameConfig,
  ISpinHistoryElement,
  Orientation,
  SymbolSelectionListener,
  SoundChangeListener,
  SpinCompleteListener,
  SpinListener,
  SpinWinAmountChangeListener,
  StakeChangeListener,
  TumbleListener,
  WinAmountChangeListener,
  IGamePosition,
  ResizeListener,
  InfoButtonClickListener,
  BasicListener,
  AssetsLoadingListener,
  BetButtonsEnabledChangeListener,
  BeforeSymbolsDropListener,
} from './types';
import GameEvent from './gameEvent';
import FpsCounter from '../debug/fpsCounter';
import CoreBalanceManager from '../game/managers/balanceManager';
import WinHistoryManager from '../game/managers/winHistoryManager';
import { SettingsManager } from '../game/managers/settingsManager';
import AssetLoader from './assetLoader';
import { ANTEBET_MULTIPLIER, LONG_DIMENSION, SHORT_DIMENSION } from './dede/resources/constants';
import Disabler from '../disabler';
import SessionIndicator from '../debug/sessionIndicator';
import { BigWinManager } from './dede/BigWinManager';
import GameController from './dede/models/gameController';
import { CoinFountainManager } from '../game/managers/coinFountionManager';
import { FreeSpinManager } from './dede/FreeSpinManager';
import SoundManager from './dede/managers/soundManager';
import { BackgroundManager } from './dede/BackgroundManager';
import { MaxWinManager } from '../game/managers/maxWinManager';
import gsap from 'gsap';

export class Game {
  sessionId!: number;
  id: string = '';
  app: Application;
  assetLoader!: AssetLoader;
  onSpin = new GameEvent<SpinListener>('onSpin');
  onSymbolSelection = new GameEvent<SymbolSelectionListener>('onSymbolSelection');

  onExplode = new GameEvent<ExplodeListener>('onExplode');
  onClick = new GameEvent<ClickListener>('onClick');
  onClickOrPress = new GameEvent<BasicListener>('onClick');
  onInfoButtonClick = new GameEvent<InfoButtonClickListener>('onInfoButtonClick');

  onSettingsButtonClick = new GameEvent<InfoButtonClickListener>('onSettingsButtonClick');

  onSoundChange = new GameEvent<SoundChangeListener>('onSoundChange');
  onPendingSpinResponseChange = new GameEvent<BasicListener>('onPendingSpinResponseChange');

  onWinAmountChange = new GameEvent<WinAmountChangeListener>('onWinAmountChange');

  onStakeChange = new GameEvent<StakeChangeListener>('onStakeChange');
  onOrientationChange = new GameEvent<OrientationChangeListener>('onOrientationChange');

  onLoaded = new GameEvent<GameLoadedListener>('onLoaded');
  onGameStateChange = new GameEvent<GameStateChangeListener>('onGameStateChange');

  onAnteBetChange = new GameEvent<AnteBetChangeListener>('onAnteBetChange');
  onTumble = new GameEvent<TumbleListener>('onTumble');
  onAssetsLoaded = new GameEvent<AssetsLoadedListener>('onAssetsLoaded');
  onFallComplete = new GameEvent<FallCompleteListener>('onFallComplete');
  onBeforeFallComplete = new GameEvent<FallCompleteListener>('onBeforeFallComplete');
  onBeforeSymbolsDrop = new GameEvent<BeforeSymbolsDropListener>('onBeforeSymbolsDrop');
  onJackpotSymbolLanded = new GameEvent<BasicListener>('onJackpotSymbolLanded');
  onBigWinAnimationCompleted = new GameEvent<BasicListener>('onBigWinAnimationCompleted');
  onActivity = new GameEvent<BasicListener>('onActivity');

  onSpinComplete = new GameEvent<SpinCompleteListener>('onSpinComplete');
  onSpinCompletedWithAllAnimations = new GameEvent<SpinCompleteListener>(
    'onSpinCompletedWithAllAnimations'
  );

  onHistoryChange = new GameEvent<HistoryChangeListener>('onHistoryChange');
  onAutoplayChange = new GameEvent<AutoPlayChangeListener>('onAutoplayChange');
  onGameConfigChange = new GameEvent<GameConfigChangeListener>('onGameConfigChange');

  onResize = new GameEvent<ResizeListener>('onResize');
  onFreeSpinUpdated = new GameEvent<FreeSpinChangeListener>('onFreeSpinUpdated');
  onNewSymbolsComing = new GameEvent<BasicListener>('onNewSymbolsComing');
  onOldSymbolsDropping = new GameEvent<BasicListener>('onOldSymbolsDropping');
  onSpacePressed = new GameEvent<BasicListener>('onSpacePressed');
  onGamePaused = new GameEvent<BasicListener>('onGamePaused');
  onGameUnPaused = new GameEvent<BasicListener>('onGameUnPaused');
  onAssetsLoading = new GameEvent<AssetsLoadingListener>('onAssetsLoading');
  onFreeSpinGained = new GameEvent<BasicListener>('onFreeSpinGained');
  onFreeSpinStarted = new GameEvent<BasicListener>('onFreeSpinStarted');
  onFreeSpinEnded = new GameEvent<BasicListener>('onFreeSpinEnded');
  onBetButtonsEnabledChange = new GameEvent<BetButtonsEnabledChangeListener>(
    'onBetButtonsEnabledChange'
  );

  static settings = new SettingsManager();
  private _pendingSpinResponse = false;
  private _freeSpinWinAmount = 0;
  private _freeSpinTotalMultiplier = 0;
  private _freeSpinActivated = false;
  private _freeSpinActivating = false;
  private _freeSpinSpinsStarted = false;
  private _winAmount: number = 0;
  private _spinWinAmount: number = 0;
  private _stake: number = 0;
  private _orientation: Orientation = 'portrait';
  private _loaded = false;
  private _isRunning: boolean = false;
  private _anteBetActive: boolean = false;
  private _history: ISpinHistoryElement[] = [];
  private _autoPlayCount: number = 0;
  private _gamePosition: IGamePosition = {
    width: 0,
    height: 0,
    xOffset: 0,
    yOffset: 0,
    scale: 1,
  };

  private _turboSpinActive = false;
  private _finishInstantly = false;

  protected _balanceManager!: CoreBalanceManager;
  winHistoryManager!: WinHistoryManager;
  bigWinManager!: BigWinManager;
  coinFountainManager!: CoinFountainManager;
  freeSpinManager!: FreeSpinManager;
  soundManager!: SoundManager;
  backgroundManager!: BackgroundManager;
  maxWinManager!: MaxWinManager;
  maxWinReached = false;

  dynamicGameContainer!: Container;
  get dynamicGameContainerRightEndX() {
    return 0;
  }

  controller!: GameController;

  betButtonsDisabler = new Disabler((disabled) =>
    this.onBetButtonsEnabledChange.triggerEvent(!disabled));

  gameDisabler = new Disabler((disabled) => {
    if (disabled) this.onGamePaused.triggerEvent();
    else this.onGameUnPaused.triggerEvent();
  });

  private _config: IGameConfig = {
    payTable: {
      symbolPayouts: [],
    },
    limits: {
      defaultBet: 0,
      winCap: 0,
      betInCoins: 0,
      legalBets: [],
      autoBets: [],
      winSizes: [],
    },
    freeSpinBuyMultiplier: 0,
    scatterSymbol: 0,
    pingPeriodMilliseconds: 5000,
  };

  index = 0;
  initialSpinDone = false;

  constructor() {
    if (this.constructor === Game) {
      throw new Error('Cannot instantiate an abstract class.');
    }

    this.app = new Application();
    if (window.location.pathname === '/testHarness') return;

    // Will register the fps counter system but won't run it unless told to do so from settings (see md file)
    new FpsCounter(this.app);
    new SessionIndicator(this.app);
    this.coinFountainManager = new CoinFountainManager(this);
    this.freeSpinManager = new FreeSpinManager(this);
    this.soundManager = new SoundManager('soundManager');
    this.backgroundManager = new BackgroundManager(this);
    this.maxWinManager = new MaxWinManager(this);

    // setTimeout(() => {
    //   this.onOrientationChange.triggerEvent(this.getOrientation());
    // }, 500);
    gsap.delayedCall(0.5, () => {
      console.log('**** orientation changed or resize');
      this.onOrientationChange.triggerEvent(this.getOrientation());
    });
    this.id = Math.random().toString(36).substring(2, 9);
    this.onAutoplayChange.addEventListener((event, { autoPlayCount: newAutoPlayCount, prev }) => {
      if (prev === 0 && newAutoPlayCount) {
        this.runReels();
      }
    });

    let spacePressed = false;

    window.addEventListener('keyup', (e) => {
      this.onActivity.triggerEvent();
      if (e.code === 'Space') {
        spacePressed = false;
      }
    });

    // get spacebar key press
    window.addEventListener('keypress', (e) => {
      this.onActivity.triggerEvent();
      if (e.code === 'Space') {
        this.onSpacePressed.triggerEvent();
        spacePressed = true;
      }
      this.onClickOrPress.triggerEvent();
    });

    window.addEventListener('click', () => {
      this.onActivity.triggerEvent();
      this.onClickOrPress.triggerEvent();
    });

    this.onSpin.addEventListener(() => {
      this.onActivity.triggerEvent();
      this.betButtonsDisabler.disable('spin');
    });
    this.onSpinComplete.addEventListener(() => {
      this.betButtonsDisabler.enable('spin');
    });
    this.onSpinComplete.addEventListener(() => {
      this.onSpinCompletedWithAllAnimations.triggerEvent();
    }, Number.MIN_VALUE);
  }

  // Abstract method
  runReels(buyFreeSpins: boolean = false): void {
    throw new Error('Abstract method \'runReels\' must be implemented.');
  }

  runFreeSpinReels(outcomes: IGameOutcome[]): void {
    throw new Error('Abstract method \'runReels\' must be implemented.');
  }

  simulate(response: string): void {
    throw new Error('Abstract method \'simulate\' must be implemented.');
  }

  onGoldenBetClick(): void {
    throw new Error('Abstract method \'onGoldenBetClick\' must be implemented.');
  }

  public get turboSpinActive(): boolean {
    return this._turboSpinActive;
  }

  public set turboSpinActive(value: boolean) {
    this._turboSpinActive = value;
  }

  public get finishInstantlyActive(): boolean {
    return this._finishInstantly;
  }

  public set finishInstantlyActive(value: boolean) {
    this._finishInstantly = value;
  }

  public get isQuickMode(): boolean {
    return this._finishInstantly || this._turboSpinActive;
  }

  public get paused(): boolean {
    return this.gameDisabler.disabled;
  }

  public get pendingSpinResponse(): boolean {
    return this._pendingSpinResponse;
  }

  public get betAmount(): number {
    return this.anteBetActive ? this.stake * ANTEBET_MULTIPLIER : this.stake;
  }

  public get maxWinAmount(): number {
    return this.config.limits.winCap * this.betAmount;
  }

  public set pendingSpinResponse(value: boolean) {
    this._pendingSpinResponse = value;
    this.onPendingSpinResponseChange.triggerEvent();
  }

  public get width(): number {
    return this._gamePosition.width;
  }

  public get height(): number {
    return this._gamePosition.height;
  }

  public get xOffset(): number {
    return this._gamePosition.xOffset;
  }

  public get yOffset(): number {
    return this._gamePosition.yOffset;
  }

  public get scale(): number {
    return this._gamePosition.scale;
  }

  public get history(): ISpinHistoryElement[] {
    return this._history;
  }

  public set history(value: ISpinHistoryElement[]) {
    this._history = value;
    this.onHistoryChange.triggerEvent(value);
  }

  get balanceManager() {
    return this._balanceManager;
  }

  public get loaded(): boolean {
    return this._loaded;
  }

  public set loaded(value: boolean) {
    if (value) {
      this._loaded = value;
      this.onLoaded.triggerEvent();
    }
  }

  public get autoPlayCount(): number {
    return this._autoPlayCount;
  }

  public set autoPlayCount(value: number) {
    if ((this.balanceManager?.balance || 0) < this.stake) {
      value = 0;
    }
    const prev = this._autoPlayCount;
    this._autoPlayCount = value;
    if (prev !== value) this.onAutoplayChange.triggerEvent({ autoPlayCount: value, prev });
  }

  public get winAmount(): number {
    return this._winAmount;
  }

  public set winAmount(value: number) {
    const prev = this._winAmount;
    this._winAmount = value;
    this.onWinAmountChange.triggerEvent({
      newWinAmount: value,
      diff: Math.min(value - prev, 0),
    });
  }

  public get freeSpinWinAmount(): number {
    return this._freeSpinWinAmount;
  }

  public set freeSpinWinAmount(value: number) {
    const prev = this._freeSpinWinAmount;
    this._freeSpinWinAmount = value;
    if (prev !== value) this._handleFreeSpinChange();
  }

  public get freeSpinTotalMultiplier(): number {
    return this._freeSpinTotalMultiplier;
  }

  public set freeSpinTotalMultiplier(value: number) {
    const prev = this._freeSpinTotalMultiplier;
    this._freeSpinTotalMultiplier = value;
    if (prev !== value) this._handleFreeSpinChange();
  }

  public get freeSpinSpinsStarted(): boolean {
    return this._freeSpinSpinsStarted;
  }

  public set freeSpinSpinsStarted(value: boolean) {
    this._freeSpinSpinsStarted = value;
  }

  public get freeSpinActivated(): boolean {
    return this._freeSpinActivated;
  }

  public set freeSpinActivated(value: boolean) {
    this._freeSpinActivated = value;
    if (this._freeSpinActivated) {
      this.freeSpinActivating = false;
    }
    else {
      this._freeSpinSpinsStarted = false;
    }
    this._handleFreeSpinChange();
  }

  public get freeSpinActivating(): boolean {
    return this._freeSpinActivating;
  }

  public set freeSpinActivating(value: boolean) {
    this._freeSpinActivating = value;
    this._handleFreeSpinChange();
  }

  public get stake(): number {
    return this._stake;
  }

  public set stake(value: number) {
    this._stake = value;
    this.onStakeChange.triggerEvent(value);
  }

  public get isRunning(): boolean {
    return this._isRunning;
  }

  protected set isRunning(value: boolean) {
    this.onActivity.triggerEvent();
    this._isRunning = value;
    this.onGameStateChange.triggerEvent(value);
  }

  public get anteBetActive(): boolean {
    return this._anteBetActive;
  }

  public set anteBetActive(value: boolean) {
    this.onActivity.triggerEvent();
    const prev = this._anteBetActive;
    this._anteBetActive = value;
    if (prev !== value) {
      this.onAnteBetChange.triggerEvent(value);
    }
  }

  public get config(): IGameConfig {
    return this._config;
  }

  public set config(value: IGameConfig) {
    if (JSON.stringify(this._config) === JSON.stringify(value)) return;
    this._config = value;
    this.onGameConfigChange.triggerEvent(value);
  }

  // methods

  private _handleFreeSpinChange() {
    this.onActivity.triggerEvent();
    this.onFreeSpinUpdated.triggerEvent({
      isActivating: this._freeSpinActivating,
      activated: this._freeSpinActivated,
      totalWinning: this._freeSpinWinAmount,
      totalMultiplier: this._freeSpinTotalMultiplier,
    });
  }

  tumble(outcome: IGameOutcome) {
    this.onTumble.triggerEvent(outcome);
  }

  onMountDone() {
    this.loaded = true;
    sound.disableAutoPause = true;
    this.onOrientationChange.triggerEvent(this.getOrientation());
  }

  getOrientation(): Orientation {
    return window.innerWidth > window.innerHeight ? 'landscape' : 'portrait';
  }

  handleMaxWinReached = () => {
    if (this.freeSpinActivated) this.freeSpinManager.stop(false);
    this.winHistoryManager.clearWinHistory();
    this.maxWinReached = true;
    this.autoPlayCount = 0;
    this.isRunning = false;
  };

  baseGameHandleResize = (orientation: Orientation) => {
    if (!this.app.renderer || !this.app.canvas) return;
    console.log('**** orientation changed or resize');
    this.onActivity.triggerEvent();
    const newOrientation = this.getOrientation();
    if (newOrientation !== this._orientation) {
      this._orientation = newOrientation;
      this.onOrientationChange.triggerEvent(newOrientation);
    }

    if (orientation === 'landscape') {
      const height = window.innerHeight * (LONG_DIMENSION / window.innerWidth);
      let scale = 1;
      let width = LONG_DIMENSION;
      if (height < SHORT_DIMENSION) {
        scale = height / SHORT_DIMENSION;
      }
      this.resize({
        scale,
        width,
        height,
        xOffset: 0,
        yOffset: 0,
      });
    }
    else {
      let scale = 1;
      let width = window.innerWidth * (LONG_DIMENSION / window.innerHeight);
      let height = LONG_DIMENSION;
      if (width < SHORT_DIMENSION) {
        scale = width / SHORT_DIMENSION;
      }
      this.resize({
        scale,
        width,
        height,
        xOffset: 0,
        yOffset: 0,
      });
    }

    this.app.renderer.resize(this.width, this.height);
    this.app.canvas.style.width = window.innerWidth + 'px';
    this.app.canvas.style.height = window.innerHeight + 'px';

    // this.renderCoordinates();
  };

  resize = (newPosition: IGamePosition) => {
    this._gamePosition = newPosition;
    this.onResize.triggerEvent(newPosition);
  };
}
