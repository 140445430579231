import { registerLogCategory } from '../../../debug/privateLogger';
import { Sound } from '@pixi/sound';
import { Assets } from 'pixi.js';
import { simpleAnimationDuration } from '../animationManager';
import { lerp } from '../../math/interpolationFunctions';

const log = registerLogCategory('SoundBase');

export interface IPlayOptions {
  onProgress?: (progress: number) => void;
}

class SoundBase {
  private _currentDuckingLevel = 0;
  private _isDucking = false;
  private _duckingVolumeMultiplier!: number;
  private _duckOutDuration!: number;
  protected _currentVolume = 1;
  private _duckRequesters = new Set<SoundBase>();
  private _duckOutAnimationRemover!: () => void;
  private _maxVolume = 1;
  protected _sound?: Sound;
  protected _soundName!: string;
  private _onCompleteSubscribers = new Set<() => void>();
  private _onAnyEndSubscribers = new Set<() => void>();
  public loop = false;

  constructor(
    soundName: string,
    {
      preventRealSound = false,
      duckingVolumeMultiplier = 0.3,
      duckOutDuration = 300,
      maxVolume = 1,
    }: {
      preventRealSound?: boolean;
      duckingVolumeMultiplier?: number;
      duckOutDuration?: number;
      maxVolume?: number;
    } = {}
  ) {
    log(1)('constructor', soundName, { duckingVolumeMultiplier, duckOutDuration, maxVolume });

    if (!preventRealSound) this._sound = Assets.get(soundName);
    this._soundName = soundName;

    this._duckingVolumeMultiplier = duckingVolumeMultiplier;
    this._duckOutDuration = duckOutDuration;

    this._maxVolume = maxVolume;
    this.volume = this.getVolume();
  }

  get soundName() {
    return this._soundName;
  }

  onComplete(callback: () => void) {
    this._onCompleteSubscribers.add(callback);
  }

  onAnyEnd(callback: () => void) {
    this._onAnyEndSubscribers.add(callback);
  }

  protected _processCompleteListeners() {
    log(2)('_handleComplete', this._soundName);

    this._onCompleteSubscribers.forEach((callback) => callback());
    this._onCompleteSubscribers.clear();
    this._onAnyEndSubscribers.forEach((callback) => callback());
    this._onAnyEndSubscribers.clear();
  }

  protected _handleComplete() {
    this._processCompleteListeners();
    if (this.loop) this._loop();
  }

  get isPlaying() {
    if (!this._sound)
      throw new Error('SoundBase->isPlaying Must implement abstract method or provide a sound');

    return this._sound.isPlaying;
  }

  protected _loop() {
    this.play();
  }

  protected async _soundBasePlay(options?: IPlayOptions) {
    if (!this._sound)
      throw new Error(
        'SoundBase->_soundBasePlay Must implement abstract method or provide a sound'
      );

    log(2)('play', this._soundName);

    const sound = await this._sound.play({
      complete: () =>
        window.setTimeout(() => {
          this._handleComplete();
        }, 0),
    });

    sound.on('progress', (progress) => {
      options?.onProgress?.(progress);
    });
  }

  play(options?: IPlayOptions) {
    this._soundBasePlay(options);
  }

  protected _handleStop() {
    this._onAnyEndSubscribers.forEach((callback) => callback());
    this._onAnyEndSubscribers.clear();
  }

  stop() {
    if (!this._sound)
      throw new Error('SoundBase->stop Must implement abstract method or provide a sound');

    log(2)('stop', this._soundName);

    this._sound.stop();
    this._handleStop();
  }

  get name() {
    return this._soundName;
  }

  get duration() {
    if (!this._sound)
      throw new Error('SoundBase->duration Must implement abstract method or provide a sound');

    return this._sound.duration * 1000;
  }

  getVolume() {
    return this._currentVolume;
  }

  getUnadjustedVolume() {
    return this._currentVolume / this._maxVolume;
  }

  get trueVolume() {
    return lerp(1, this._duckingVolumeMultiplier, this._currentDuckingLevel) * this._currentVolume;
  }

  set volume(volume: number) {
    if (!this._sound)
      throw new Error('SoundBase->volume (set) Must implement abstract method or provide a sound');

    this._currentVolume = volume * this._maxVolume;
    this._sound.volume = this.trueVolume;

    log(4)('set volume', this._soundName, { volume, trueVolume: this._sound.volume });
  }

  duckForSound(sound: SoundBase) {
    if (!this._sound)
      throw new Error('SoundBase->duckForSound Must implement abstract method or provide a sound');

    this._duckRequesters.add(sound);
    this._checkDuckingStatus();

    sound.onAnyEnd(() => {
      this._duckRequesters.delete(sound);
      this._checkDuckingStatus();
    });
  }

  private _checkDuckingStatus() {
    if (this._duckRequesters.size > 0 && !this._isDucking) {
      this._isDucking = true;
      this._startDucking();
    }
    else if (this._duckRequesters.size === 0 && this._isDucking) {
      this._isDucking = false;
      this._stopDucking();
    }
  }

  private _startDucking() {
    log(2)('startDucking', this._soundName);

    this._duckOutAnimationRemover?.();

    this._currentDuckingLevel = 1;
    this._sound!.volume = this.trueVolume;
  }

  private _stopDucking() {
    log(2)('stopDucking', this._soundName);

    this._duckOutAnimationRemover = simpleAnimationDuration(
      this._duckOutDuration,
      ({ progress }) => {
        this._currentDuckingLevel = 1 - progress;
        this._sound!.volume = this.trueVolume;
      }
    ).removeAnimation;
  }

  get status() {
    if (!this._sound)
      throw new Error('SoundBase->status Must implement abstract method or provide a sound');

    return this._sound.instances.filter((instance) => !instance.paused).length;
  }
}

export default SoundBase;
