import SoundTrack from '../../../../game/managers/soundManager/soundTrack';
import NormalThemeTrack from './normalThemeTrack';
import FreeSpinTrack from './freeSpinTrack';
import CachedSettingsManager from '../../../../cachedSettingsManager';

class GeneralGameplayMusicTrack extends SoundTrack {
  private _normalThemeTrack?: NormalThemeTrack;
  private _freeSpinTrack?: FreeSpinTrack;
  private _currentSong: 'main' | 'freeSpin' | 'jackpot' | 'winMode' = 'main';

  mount() {
    this._normalThemeTrack = new NormalThemeTrack('normalThemeTrack');
    this._normalThemeTrack?.mount();
    this.registerTrack('normalThemeTrack', this._normalThemeTrack);
    this._freeSpinTrack = new FreeSpinTrack('freeSpinTrack');
    this._freeSpinTrack?.mount();
    this.registerTrack('freeSpinTrack', this._freeSpinTrack);

    this.registerMusic('musBaseGameWinMode', { loop: true, maxVolume: 0.5 });

    this._isMounted = true;

    CachedSettingsManager.addListener('soundControlState', (value) => {
      const { musicEnabled, gameSoundEnabled } = value as unknown as {
        musicEnabled: boolean;
        gameSoundEnabled: boolean;
      };
      this.volume = musicEnabled && gameSoundEnabled ? 1 : 0;
    });

    const { musicEnabled, gameSoundEnabled } = CachedSettingsManager.get(
      'soundControlState'
    ) as unknown as {
      musicEnabled: boolean;
      gameSoundEnabled: boolean;
    };
    this.volume = musicEnabled && gameSoundEnabled ? 1 : 0;
  }

  startGame() {
    this._normalThemeTrack?.playFromIntro();
    this._currentSong = 'main';
  }

  playMainMusic(transitionFromFreeSpin = false) {
    if (
      this._currentSong === 'main'
      || (this._currentSong === 'freeSpin' && !transitionFromFreeSpin)
    )
      return;

    this._normalThemeTrack?.play();
    this._currentSong = 'main';

    this.crossFade([this.getSound('musBaseGameWinMode')!], this.getSound('normalThemeTrack')!, {
      stopAndRestoreVolume: true,
      duration: 1500,
    });
  }

  playWinModeMusic() {
    if (this._currentSong !== 'main') return;

    this._currentSong = 'winMode';
    const winModeSong = this.getSound('musBaseGameWinMode')!;
    winModeSong.play();

    this.crossFade([this.getSound('normalThemeTrack')!], winModeSong, { stopAndRestoreVolume: true });
  }

  playFreeSpinMusic() {
    if (this._currentSong === 'freeSpin') return;

    this._currentSong = 'freeSpin';
    this._freeSpinTrack?.play();

    this.crossFade(
      [this.getSound('musBaseGameWinMode')!, this.getSound('normalThemeTrack')!],
      this.getSound('freeSpinTrack')!,
      { stopAndRestoreVolume: true }
    );
  }

  stopFreeSpinMusic() {
    this._freeSpinTrack?.stop();
    this._freeSpinTrack?.onComplete(() => {
      this.playMainMusic(true);
    });
  }
}

export default GeneralGameplayMusicTrack;
