import { Application, Container, Graphics, Point, Sprite, Text, Texture } from 'pixi.js';
import GameEvent from '../../../gameEvent';
import { ResizeListener } from '../../../types';
import { ReelsManager } from '../reelsManager';
import { GlowFilter } from 'pixi-filters';
import { formatAsCurrency } from '../../../../game/managers/currencyManager';
import { registerLogCategory } from '../../../../debug/privateLogger';
import { performCurrencyCountUp } from '../../../../game/managers/animationManager/animations';
import { Game } from '../../../game';
import { Dede } from '../..';
import { textWidthLimiter } from '../../../../layoutTools/pixiComponentManagement';

const log = registerLogCategory('LayoutManager');

class LayoutManager {
  private _pixiWinHistoryContainer!: Container;
  private _pixiTotalWinContainer!: Container;

  constructor(private _game: Dede) {}

  get onResize() {
    return this._game.onResize;
  }

  public setupPixiContainers() {
    this._prepareBetBalanceBackground();
    this._setupWiningsBarContainer();
  }

  /** WiningsBar Container */

  static winningsBarContainerXOffset = 1170;
  static winningsBarContainerYOffset = 780;
  static winningsBarContainerWidth = 633;
  static winningsBarContainerHeight = 74;
  static winHistoryWidthLimit = 430;

  static winHistoryPortraitXOffset = 2260;
  static winHistoryPortraitYOffset = -2700;
  static winHistoryLandscapeYOffset = -1910;
  static winHistoryLandscapeXOffset = 11;

  get visibleWidthStageCoordinate() {
    return this._game.app.stage.toLocal(new Point(this._game.app.renderer.screen.width, 0)).x;
  }

  get visibleHeightStageCoordinate() {
    return this._game.app.stage.toLocal(new Point(0, this._game.app.renderer.screen.height)).y;
  }

  // Sets up a container that will hold both the balance and win history elements.
  private _setupWiningsBarContainer() {
    log(1)('LayoutManager->setupWiningsBarContainer', {
      gameInterface: this._game,
      LayoutManager,
    });

    const winningsBarContainer = new Container();
    this._game.reelsManager.containerSprite.addChild(winningsBarContainer);

    winningsBarContainer.x = LayoutManager.winningsBarContainerXOffset;
    winningsBarContainer.y = LayoutManager.winningsBarContainerYOffset;
    winningsBarContainer.width = LayoutManager.winningsBarContainerWidth;
    winningsBarContainer.height = LayoutManager.winningsBarContainerHeight;

    const winHistoryContainer = new Sprite(Texture.from('historyContainer'));
    winHistoryContainer.zIndex = 3;
    winHistoryContainer.anchor.set(0.5, 0);
    window.game!.app.stage.addChild(winHistoryContainer);

    this._pixiWinHistoryContainer = winHistoryContainer;

    const totalWinContainer = new Container();
    winningsBarContainer.addChild(totalWinContainer);
    this._pixiTotalWinContainer = totalWinContainer;

    const handleResize = () => {
      const orientation = window.game!.getOrientation();

      let scale = this._game.scale;

      if (orientation === 'portrait') {
        winHistoryContainer.x =
          this._game.reelsManager.container.x + LayoutManager.winHistoryPortraitXOffset * scale;
        winHistoryContainer.y = this._game.height - this.winHistoryContainer.height - 190 * scale;
      } else {
        winHistoryContainer.x =
          this._game.dynamicGameContainerRightEndX +
          (this._game.width - this._game.dynamicGameContainerRightEndX) / 2;

        winHistoryContainer.y =
          this._game.reelsManager.container.y +
          this._game.reelsManager.container.height +
          LayoutManager.winHistoryLandscapeYOffset * scale;
      }
      winHistoryContainer.scale.set(scale * 0.75);
    };
    this.onResize.addEventListener(handleResize, -5);
    handleResize();
  }

  get winHistoryContainer() {
    if (typeof this._pixiWinHistoryContainer === 'undefined')
      throw new Error(
        'Cannot access LayoutManager->winHistoryContainer without setupPixiContainers having been called'
      );

    return this._pixiWinHistoryContainer;
  }

  get totalWinContainer() {
    if (typeof this._pixiTotalWinContainer === 'undefined')
      throw new Error(
        'Cannot access LayoutManager->totalWinContainer without setupPixiContainers having been called'
      );

    return this._pixiTotalWinContainer;
  }

  /** Balance and Bet Text */

  private _prepareBetBalanceBackground() {
    let orientation = this._game.getOrientation();
    const separatorSprite = new Sprite(Texture.from('tumbleWinSeparator'));
    separatorSprite.anchor.set(0.5);
    separatorSprite.zIndex = 4;
    const bottomTextGradientSprite = new Sprite(
      Texture.from(
        orientation === 'portrait'
          ? 'betBalancePortraitBackground'
          : 'betBalanceLandscapeBackground'
      )
    );
    this._game.app.stage.addChild(separatorSprite);
    this._game.app.stage.addChild(bottomTextGradientSprite);
    bottomTextGradientSprite.anchor.set(0.5);
    bottomTextGradientSprite.x = this._game.width / 2;
    bottomTextGradientSprite.zIndex = 2;

    const handleResize = () => {
      const newOrientation = this._game.getOrientation();
      if (orientation !== newOrientation) {
        orientation = newOrientation;
        bottomTextGradientSprite.texture = Texture.from(
          orientation === 'portrait'
            ? 'betBalancePortraitBackground'
            : 'betBalanceLandscapeBackground'
        );
      }

      if (orientation === 'portrait') {
        separatorSprite.visible = true;
        separatorSprite.scale.set(this._game.scale * 0.8);
        separatorSprite.x = this._game.width / 2 + 8;
        separatorSprite.y = this._game.height - separatorSprite.height / 2;
        bottomTextGradientSprite.scale.set(this._game.scale * 2);
        bottomTextGradientSprite.x = this._game.width / 2;
        bottomTextGradientSprite.y = this._game.height - bottomTextGradientSprite.height / 2;
      } else {
        separatorSprite.visible = false;
        bottomTextGradientSprite.scale.set(this._game.scale);
        bottomTextGradientSprite.x = 1163 * this._game.scale + this._game.dynamicGameContainer.x;
        bottomTextGradientSprite.y =
          this._game.dynamicGameContainer.y +
          this._game.dynamicGameContainer.height -
          1270 * this._game.scale;
      }
    };

    this.onResize.addEventListener(handleResize);
    handleResize();
  }

  static createBalanceStyleText(
    parentContainer: Container,
    {
      anchorX = 0,
      anchorY = 1,
      x,
      y,
      label,
    }: {
      anchorX?: number;
      anchorY?: number;
      x: number;
      y: number;
      label: string;
    }
  ) {
    log(3)('createBalanceStyleText', { anchorX, anchorY, x, y, label });

    const container = new Container();
    parentContainer.addChild(container);

    const labelText = new Text();
    container.addChild(labelText);
    labelText.anchor.set(anchorX, anchorY);
    labelText.y = 0;
    labelText.style = {
      fill: 0xffffff,
      fontSize: 46,
      fontFamily: 'ManchoBold',
    };

    const valueText = new Text();
    container.addChild(valueText);
    valueText.anchor.set(anchorX, anchorY);
    valueText.y = 0;
    valueText.style = {
      fill: 0xfe9b1c,
      fontSize: 46,
      fontFamily: 'ManchoBold',
    };

    const glowFilter = new GlowFilter({
      distance: 3,
      outerStrength: 4,
      innerStrength: 0,
      color: 0x000000,
      quality: 0.5,
    });

    container.filters = [glowFilter];

    container.x = x;
    container.y = y;
    container.zIndex = 2;

    const updateValue = async (
      maxWidth: number,
      value?: number,
      prevValue?: number,
      withAnimation?: boolean
    ) => {
      if (typeof value === 'undefined') {
        valueText.text = '';
        labelText.text = '';

        return;
      }

      labelText.text = label;

      if (withAnimation) {
        performCurrencyCountUp(valueText, 1200, prevValue! || 0, value, maxWidth).promise;
      } else {
        const scale = textWidthLimiter(valueText, maxWidth, formatAsCurrency(value));
        valueText.scale.set(scale);
        valueText.text = formatAsCurrency(value);
      }

      labelText.x = 0;
      valueText.x = 0;

      if (anchorX === 1) labelText.x = 0 - maxWidth - 13;
      else valueText.x = labelText.width + 13;
    };

    updateValue(250);

    return {
      updateValue,
      container,
      labelText,
    };
  }
}

export default LayoutManager;
