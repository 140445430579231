import { Container, Text } from 'pixi.js';
import { registerLogCategory } from '../../../../debug/privateLogger';
import { IEventDetails } from '../../../gameEvent';
import CoreBalanceManager from '../../../../game/managers/balanceManager';
import { TBalanceEvent } from '../../../../game/managers/balanceManager/balanceManager.types';
import LayoutManager from '../layoutManager';
import { getLocale } from '../../../../../localization';

const log = registerLogCategory('BalanceManager');

class BalanceManager extends CoreBalanceManager {
  // Used to prevent mount from happening twice
  private _pixiBalanceContainerIsMounted = false;
  private _valueUpdater!: (
    maxWidth: number,
    value?: number,
    prevValue?: number,
    withAnimation?: boolean
  ) => void;

  private _labelText!: Text;

  private _updateValueOfTextControl!: (value: number, prevValue: number) => void;

  // Needs to be called after pixi app is ready to show stuff
  mountPixiBalanceContainer(container: Container) {
    log(2)('mountPixiBalanceContainer', {
      _pixiContainer: container,
      isMounted: this._pixiBalanceContainerIsMounted,
    });
    if (this._pixiBalanceContainerIsMounted) return;

    const {
      updateValue,
      container: textContainer,
      labelText,
    } = LayoutManager.createBalanceStyleText(container, {
      x: this.game.width - 10,
      y: this.game.height - 20,
      label: getLocale('slots.ui.common.balance').toLocaleUpperCase(),
    });
    this._valueUpdater = updateValue;

    this._pixiContainer = textContainer;
    this._labelText = labelText;

    this._pixiBalanceContainerIsMounted = true;

    this._handleResize();
    this._updateBalanceText();

    this.game.onResize.addEventListener(this._handleResize);
  }

  private _handleResize = () => {
    const orientation = this.game.getOrientation();
    this._pixiContainer.scale.set(this.game.scale);

    if (orientation === 'portrait') {
      this._pixiContainer.y = this.game.height - 20 * this.game.scale;
      this._pixiContainer.x = this.game.width / 2 + 60 * this.game.scale;

      this._updateValueOfTextControl = (value: number, prevValue: number) =>
        this._valueUpdater(
          this.game.width / 2 - (this._labelText.x + this._labelText.width + 10) * this.game.scale,
          value,
          prevValue,
          value > prevValue
        );
    } else {
      this._pixiContainer.y =
        this.game.dynamicGameContainer.y +
        this.game.dynamicGameContainer.height -
        1230 * this.game.scale;
      this._pixiContainer.x = 993 * this.game.scale + this.game.dynamicGameContainer.x;

      this._updateValueOfTextControl = (value: number, prevValue: number) =>
        this._valueUpdater(250, value, prevValue, value > prevValue);
    }
  };

  private _updateBalanceText() {
    log(3)('_updateBalanceText', {
      isMounted: this._pixiBalanceContainerIsMounted,
      balance: this._balance,
      textEl: this._pixiContainer,
    });

    if (!this._pixiBalanceContainerIsMounted) return;

    this._updateValueOfTextControl(this._balance || 0, this._prevBalance || 0);
  }

  // A listener for this is set up by the core BalanceManager class
  protected _handleBalanceChanged(event: IEventDetails, balanceEvent: TBalanceEvent) {
    log(3)('_handleBalanceChanged called', { event, balanceEvent });
    this._updateBalanceText();
  }
}

export default BalanceManager;
