import { BitmapText, Graphics, Sprite, Text, Texture } from 'pixi.js';
import { Dede } from '.';
import { IFreeSpin, IGameOutcome, IGameSpinResponse } from './service/types';
import GameEvent, { IEventDetails } from '../gameEvent';
import Popup from './models/popup';
import { IFreeSpinStatus } from '../types';
import { getLocale } from '../../../localization';
import { formatAsCurrency } from '../../game/managers/currencyManager';
import { splitSentence } from '../../utils/text';
import { fontSafeString } from './resources/fonts/fonts';
import { Spine } from '@pixi/spine-pixi';
import { TransitionManager } from '../../game/managers/transitionManager';
import { Game } from '../game';
import accamaxDebug from '../../debug';

type TFreeSpinTransitionListener = (event: IEventDetails, freeSpinTransitionEvent: boolean) => void;

const consecutiveWinsForWinMusic = 2;
const winMusicTimeout = 15000;

const LANDSCAPE_REMAIN_X_OFFSET = -1266;
const LANDSCAPE_MULTIPLIER_X_OFFSET = -1135;
const PORTRAIT_REMAIN_X_OFFSET = 1410;
const PORTRAIT_REMAIN_Y_OFFSET = -2704;
const PORTRAIT_MULTIPLIER_X_OFFSET = 1890;
const PORTRAIT_MULTIPLIER_Y_OFFSET = -2580;
export class FreeSpinManager {
  private freeSpins: IFreeSpin[] = [];
  private numberOfFreeSpinsTriggered = 0;
  private _transitionManager: TransitionManager;
  startPopup?: Popup;
  totalMultiplierText!: BitmapText;
  resultContainer?: Graphics;
  freeSpinResponse?: IGameSpinResponse;

  remainingFreeSpinsContainer?: Sprite;
  multiplierSpine?: Spine;
  remainingFreeSpinText!: Text;
  startFreeSpinButton?: Sprite;
  freeSpinIndex = 0;
  private _consecutiveWins = 0;
  private _winMusicTimer?: ReturnType<typeof setTimeout> | undefined = undefined;
  private _triggerWinModeMusic = false;

  onFreeSpinTransition = new GameEvent<TFreeSpinTransitionListener>('onFreeSpinTransition');
  private _lastFreeSpinChange = false;

  constructor(private game: Game) {
    this.game.onFreeSpinUpdated.addEventListener((event, status: IFreeSpinStatus) => {
      if (this._lastFreeSpinChange && !status.activated) {
        this._lastFreeSpinChange = false;
        this.onFreeSpinTransition.triggerEvent(false);
      }
    });

    this.game.onSpinComplete.addEventListener((event, { tumbleWinAmount, winningSymbols }) =>
      this._handleSpinComplete(event, { tumbleWinAmount, winningSymbols: winningSymbols ?? [] }));
    this._transitionManager = new TransitionManager(this.game);
    // for demo purpose mount freespin graphics after 3 seconds
    // setTimeout(() => {
    //   this.mountGraphics();
    // }, 3000);

    accamaxDebug.debug.triggerWinMode = () => {
      this._triggerWinModeMusic = true;
      this._consecutiveWins = consecutiveWinsForWinMusic;
    };
  }

  private _handleSpinComplete(
    event: unknown,
    { tumbleWinAmount, winningSymbols }: { tumbleWinAmount: number; winningSymbols: number[] }
  ) {
    const hasFreeSpinWin = (winningSymbols ?? []).includes(this.game.config.scatterSymbol);
    if (tumbleWinAmount && !hasFreeSpinWin) this._consecutiveWins++;
    else this._consecutiveWins = 0;

    if (
      !this._winMusicTimer &&
      (this._consecutiveWins >= consecutiveWinsForWinMusic || this._triggerWinModeMusic) &&
      !this.game.freeSpinActivated
    ) {
      this._triggerWinModeMusic = false;
      this._winMusicTimer = setTimeout(() => this._clearConsecutiveWinState(), winMusicTimeout);
      this.game.soundManager.generalGameplayMusicTrack?.playWinModeMusic();
    }
  }

  updateWinStreakTimer() {
    if (this._winMusicTimer) {
      clearTimeout(this._winMusicTimer);
      this._winMusicTimer = setTimeout(() => this._clearConsecutiveWinState(), winMusicTimeout);
    }
  }

  private _clearConsecutiveWinState() {
    if (this._winMusicTimer) clearTimeout(this._winMusicTimer);
    this._winMusicTimer = undefined;
    this._consecutiveWins = 0;

    this.game.soundManager.generalGameplayMusicTrack?.playMainMusic();
  }

  transitionBack = async () => {
    const transitionAnimationTime = this._transitionManager.animateTransition(() => {
      this.game.onFreeSpinEnded.triggerEvent();
    }, true);
    await new Promise((res) => setTimeout(res, transitionAnimationTime));
    this.game.onSpinComplete.removeEventListener(this.handleSpinComplete);
  };

  stop = (showPopup = true) => {
    this.game.onSpinComplete.removeEventListener(this.handleSpinComplete);
    this.game.freeSpinActivated = false;
    this.unmountGraphics();

    if (showPopup) {
      const resultPopup = new Popup(
        this.game.freeSpinWinAmount
          ? [
              ...[
                getLocale('slots.ui.common.congratulations'),
                ...splitSentence(getLocale('slots.ui.common.youWon'), 20),
              ].map((el) => ({ label: el })),
              {
                fontFamily: 'goldenTextFontOld',
                label: formatAsCurrency(this.game.freeSpinWinAmount),
                marginTop: 20,
              },
            ]
          : splitSentence(
              getLocale('slots.ui.common.betterLuckNextTimeXX', formatAsCurrency(0)),
              20
            ).map((el) => ({ label: el })),
        {
          onClose: this.transitionBack,
        }
      );
      resultPopup.mount();
    }
  };

  spinNext = async () => {
    if (this.game.maxWinReached) return;
    this.game.winHistoryManager.clearWinHistory();
    this.game.freeSpinSpinsStarted = true;
    const spin = this.freeSpins[this.freeSpinIndex];

    if (spin.spinsAdded) {
      const showMoreFreeSpinsPopup = async () => {
        this.game.gameDisabler.disable('newFreeSpins');
        await this.game.onFreeSpinGained.triggerEvent();

        let resolve: () => void;
        const promise = new Promise<void>((res) => {
          resolve = res;
        });
        const freeSpinSprite = new Sprite(Texture.from('freeSpin'));
        freeSpinSprite.anchor.set(0.5);
        const popup = new Popup(
          [
            getLocale('slots.ui.common.congratulations'),
            ...splitSentence(
              getLocale('slots.ui.common.youWonXXMoreFreeSpins', spin.spinsAdded),
              15
            ),
          ].map((el) => ({ label: el })),
          {
            onClose: () => {
              this.game.gameDisabler.enable('newFreeSpins');
              this.numberOfFreeSpinsTriggered = spin.spinsLeft + spin.spinsPlayed;
              resolve();
            },
            sprites: [{ sprite: freeSpinSprite, yOffset: 20 }],
          }
        );
        popup.mount();
        await promise;
      };

      this.game.onSpinCompletedWithAllAnimations.addEventListenerOnce(showMoreFreeSpinsPopup);
    }

    this.game.runFreeSpinReels(spin.outcomes);
    const updateAggregatedMultiplier = () => {
      this.game.freeSpinTotalMultiplier = spin.aggregatedMultiplier;
      this.game.onSpinComplete.removeEventListener(updateAggregatedMultiplier);
    };
    this.game.onSpinComplete.addEventListener(updateAggregatedMultiplier);
    this.freeSpinIndex++;
  };

  handleSpinComplete = async () => {
    this.game.freeSpinWinAmount = this.freeSpinResponse?.freeSpins?.at(-1)?.runningTotal || 0;
    if (this.freeSpinIndex < this.freeSpins.length) {
      if (this.game.paused) {
        const handleGameUnPaused = async () => {
          this.game.onGameUnPaused.removeEventListener(handleGameUnPaused);
          await this.spinNext();
          this.updateIndicators();
        };
        this.game.onGameUnPaused.addEventListener(handleGameUnPaused);
      }
      else {
        await this.spinNext();
        this.updateIndicators();
      }
    }
    else {
      this.stop();
    }
  };

  processSpinResponse(spinResponse: IGameSpinResponse) {
    this.freeSpinResponse = spinResponse;
    if (spinResponse.freeSpinsTriggered) {
      this.game.controller.spinButton.disabled = true;
      this.game.autoPlayCount = 0;
      const startFreeSpin = async () => {
        this.game.controller.spinButton.disabled = false;
        const transitionAnimationTime = this._transitionManager.animateTransition(() => {
          this.game.onFreeSpinStarted.triggerEvent();
        });
        await new Promise((res) => setTimeout(res, transitionAnimationTime));

        this.freeSpinIndex = 0;
        this.game.freeSpinWinAmount = 0;
        this._lastFreeSpinChange = true;
        this.onFreeSpinTransition.triggerEvent(true);

        spinResponse.freeSpins.forEach((spin, x) => {
          const prevSpin = spinResponse.freeSpins[x - 1];
          spin.outcomes.forEach((outcome, index) => {
            outcome.isLastOutcome = index === spin.outcomes.length - 1;
            const spinData = {
              ...spin,
              outcomeLength: spin.outcomes.length,
            };
            const prevSpinData = prevSpin && {
              ...prevSpin,
              outcomeLength: prevSpin.outcomes.length,
            };
            outcome.index = index;
            outcome.spinData = spinData;
            outcome.prevSpinData = prevSpinData;
            outcome.isFreeSpin = true;
            outcome.totalWinAmount = outcome.isLastOutcome
              ? spin.runningTotal
              : (prevSpin?.runningTotal || 0) + outcome.runningTotal;
            outcome.tumbleWinAmount = outcome.isLastOutcome ? spin.winAmount : outcome.runningTotal;

            outcome.isLastFreeSpinOutcome
              = x === spinResponse.freeSpins.length - 1 && index === spin.outcomes.length - 1;
          });
        });
        this.freeSpins = spinResponse.freeSpins;

        await this.spinNext();
        this.mountGraphics();

        this.game.onSpinComplete.addEventListener(this.handleSpinComplete);
      };
      const mountPopupCallback = async () => {
        this.game.freeSpinTotalMultiplier = 0;
        this.game.freeSpinActivated = true;
        this.numberOfFreeSpinsTriggered = spinResponse.numberOfFreeSpinsTriggered;

        this.game.onSpinComplete.removeEventListener(mountPopupCallback);
        await this.game.onFreeSpinGained.triggerEvent();

        const freeSpinSprite = new Sprite(Texture.from('freeSpin'));
        freeSpinSprite.anchor.set(0.5);

        this.startPopup = new Popup(
          [
            getLocale('slots.ui.common.congratulations'),
            ...splitSentence(
              getLocale('slots.ui.common.youWonXXFreeSpins', this.numberOfFreeSpinsTriggered),
              15
            ),
          ].map((el) => ({ label: el })),
          {
            onClose: startFreeSpin,
            sprites: [{ sprite: freeSpinSprite, yOffset: 30 }],
          }
        );
        this.startPopup.mount();

        this.game.soundManager.generalGameplayMusicTrack?.playFreeSpinMusic();
      };

      this.game.onSpinComplete.addEventListenerOnce(() => {
        GameEvent.mainQueue.add(mountPopupCallback, 2);
      });
    }
  }

  animateWin = async () => {
    if (!this.multiplierSpine?.state) return;
    const animation = this.multiplierSpine.state.setAnimation(0, 'FS_indicator_win', false);

    animation.listener = {
      complete: () => {
        setTimeout(() => {
          this.multiplierSpine?.state.setAnimation(0, 'FS_indicator_idle', true);
        });
      },
    };
  };

  async mountGraphics() {
    const sprite = new Sprite(Texture.from('anteBetBuyBonusButton'));

    const title = new Text();
    sprite.addChild(title);
    title.text = getLocale('slots.ui.common.freeSpins').toUpperCase();
    title.anchor.set(0.5);
    title.style = { fill: 0xffffff, fontSize: 62, fontFamily: 'ManchoBold' };
    title.x = sprite.width / 2;
    title.y = 120;
    title.scale.set((sprite.width * 0.8) / title.width);

    const remainText = new Text();
    sprite.addChild(remainText);
    remainText.style = {
      fill: 0xffffff,
      fontSize: 70,
      align: 'center',
      fontFamily: 'ManchoBold',
    };
    remainText.y = 200;
    remainText.x = sprite.width / 2;
    remainText.anchor.set(0.5);

    const spine = Spine.from({ skeleton: 'multiplierData', atlas: 'multiplierAtlas' });
    spine.state.setAnimation(0, 'FS_indicator_idle', true);
    spine.scale.set(0.5);
    spine.zIndex = 10;

    this.multiplierSpine = spine;

    const multiplierText = new BitmapText({
      text: fontSafeString('symbolOverlayFontOld', ' '),
      style: {
        fontFamily: 'symbolOverlayFontOld',
        fontSize: 300, // fontSize / scale,
        // letterSpacing: symbolTextSpacingAdjust[symbolCategory],
      },
    });

    spine.addChild(multiplierText);
    multiplierText.y = 20;
    multiplierText.x = 0;
    multiplierText.anchor.set(0.5);

    this.game.app.stage.addChild(spine);
    this.game.app.stage.addChild(sprite);
    this.remainingFreeSpinsContainer = sprite;
    this.remainingFreeSpinText = remainText;
    this.totalMultiplierText = multiplierText;
    this.remainingFreeSpinsContainer.zIndex = 5;

    this.updateIndicators();
    this.handleResize();
    this.game.onResize.addEventListener(this.handleResize);
  }

  updateIndicators() {
    this.remainingFreeSpinText.text = `${
      this.numberOfFreeSpinsTriggered - this.freeSpinIndex + 1
    } / ${this.numberOfFreeSpinsTriggered}`;
  }

  async unmountGraphics() {
    this.game.onResize.removeEventListener(this.handleResize);
    this.game.soundManager.generalGameplayMusicTrack?.stopFreeSpinMusic();
    this.remainingFreeSpinsContainer?.destroy({ children: true });
    this.totalMultiplierText?.destroy();
    this.multiplierSpine?.destroy();
    this.game.winHistoryManager.clearWinHistory();
  }

  handleResize = () => {
    if (this.remainingFreeSpinsContainer && this.multiplierSpine) {
      const orientation = this.game.getOrientation();
      let scale = this.game.scale;
      if (orientation === 'portrait') {
        this.remainingFreeSpinsContainer.scale = this.game.scale * 0.8;
        this.remainingFreeSpinsContainer.x
          = this.game.dynamicGameContainer.x + PORTRAIT_REMAIN_X_OFFSET * scale;
        this.remainingFreeSpinsContainer.y
          = this.game.height - this.remainingFreeSpinsContainer.height - 220 * scale;

        this.multiplierSpine.scale = this.game.scale * 0.2;
        this.multiplierSpine.x
          = this.game.dynamicGameContainer.x + PORTRAIT_MULTIPLIER_X_OFFSET * scale;
        this.multiplierSpine.y = this.game.height - this.multiplierSpine.height - 150 * scale;
      }
      else {
        this.remainingFreeSpinsContainer.x
          = this.game.dynamicGameContainer.x
          + this.game.dynamicGameContainer.width
          + LANDSCAPE_REMAIN_X_OFFSET * this.game.scale;
        this.remainingFreeSpinsContainer.y
          = this.game.dynamicGameContainer.y + 100 * this.game.scale;
        this.remainingFreeSpinsContainer.scale = this.game.scale * 0.8;

        this.multiplierSpine.scale = this.game.scale * 0.2;
        this.multiplierSpine.x
          = this.game.dynamicGameContainer.x
          + this.game.dynamicGameContainer.width
          + LANDSCAPE_MULTIPLIER_X_OFFSET * this.game.scale;
        this.multiplierSpine.y = this.game.dynamicGameContainer.y + 900 * this.game.scale;
        this.remainingFreeSpinsContainer.scale = this.game.scale * 0.8;
      }
    }
  };
}
