import { Dede } from '..';
import { getGameInitData, getGameSpin, getGameStats } from '../service';
import { IGameInitResponse, IGameSpinResponse } from '../service/types';
import GameEvent from '../../gameEvent';
import { SpinListener } from '../../types';
import { registerLogCategory } from '../../../debug/privateLogger';
import MockInitResponse from './mockInitResponse.json';
import MockSpinResponse from './mockSpinResponse.json';

const log = registerLogCategory('ServiceManager');

export class ServiceManager {
  balancePullingInterval?: NodeJS.Timeout;
  simulateSpinResponse?: { data?: IGameSpinResponse };
  onSpin = new GameEvent<SpinListener>('onSpin');

  constructor(private game: Dede) {
    log(1)('ServiceManager started', { Dede });
  }

  async init() {
    let initData: IGameInitResponse;
    if (process.env.REACT_APP_SERVERLESS === 'true') {
      initData = MockInitResponse as unknown as IGameInitResponse;
    } else {
      initData = await getGameInitData(this.game.id);
    }
    this.game.onNewSymbolsComing.triggerEvent();
    this.game.config = initData.config;
    this.game.sessionId = initData.gameSessionId;
    log(2)('initGame', {
      initData,
      game: this.game,
      balancePullingInterval: this.balancePullingInterval,
    });
    // @todo This should not run like this, we should listen to a service manager event instead
    this.game.balanceManager.setBalance(initData.stats.balance);
    this.game.jackpotManager.updateJackpotValues({
      grandJackpotValue: initData.grandJackpot.balance,
      majorJackpotValue: initData.majorJackpot.balance,
    });
    this.game.stake = initData.config.limits.defaultBet;
    this.game.reelsManager.loadNumbers(initData.outcomes[0]?.symbols, true);
    clearInterval(this.balancePullingInterval!);
    this.balancePullingInterval = setInterval(() => {
      if (
        this.game.isRunning ||
        this.game.freeSpinActivating ||
        this.game.freeSpinActivated ||
        this.game.gameDisabler.disabled
      )
        return;
      this.getStats();
    }, this.game.config.pingPeriodMilliseconds);
  }

  async getStats() {
    if (process.env.REACT_APP_SERVERLESS === 'true') return;
    const stats = await getGameStats(this.game.id);
    log(4)('getStats', {
      stats,
      game: this.game,
      balancePullingInterval: this.balancePullingInterval,
      spinResponse: this.simulateSpinResponse,
    });

    // @todo This should not run like this, we should listen to a service manager event instead

    if (!this.game.isRunning && !this.game.freeSpinActivating && !this.game.freeSpinActivated) {
      this.game.balanceManager.setBalance(stats.stats.balance);
    }
    this.game.jackpotManager.updateJackpotValues({
      grandJackpotValue: stats.grandJackpot.balance,
      majorJackpotValue: stats.majorJackpot.balance,
    });
  }

  loadSimulateResponse(response: string) {
    const responseParsed = JSON.parse(response.trim());
    log(2)('loadSimulateResponse', { response, responseParsed });
    this.simulateSpinResponse = responseParsed;
  }

  async getSpin(buyFreeSpins: boolean = false) {
    this.game.pendingSpinResponse = true;
    let spinResult: IGameSpinResponse;
    if (process.env.REACT_APP_SERVERLESS === 'true') {
      spinResult = MockSpinResponse as unknown as IGameSpinResponse;
    } else {
      spinResult = this.simulateSpinResponse?.data || (await getGameSpin(this.game, buyFreeSpins));
    }
    log(2)('getSpin', { spinResult, game: this.game });

    if (this.simulateSpinResponse?.data) {
      delete this.simulateSpinResponse.data;
    }

    this.game.outcomes = spinResult.outcomes.map((outcome, index) => {
      outcome.index = index;
      outcome.isLastOutcome = index === spinResult.outcomes.length - 1;
      outcome.tumbleWinAmount = outcome.isLastOutcome ? spinResult.winAmount : outcome.runningTotal;
      outcome.totalWinAmount = outcome.tumbleWinAmount;
      const spinData = {
        ...spinResult,
        outcomeLength: spinResult.outcomes.length,
      };
      outcome.spinData = spinData;
      return outcome;
    });

    this.game.freeSpinManager.processSpinResponse(spinResult);
    this.game.jackpotManager.processSpinResponse(spinResult);
  }
}
