import { Container, Text } from 'pixi.js';
import { Dede } from '.';
import LayoutManager from './managers/layoutManager';
import { ANTEBET_MULTIPLIER } from './resources/constants';
import { getLocale } from '../../../localization';

const MAX_VALUE_WITH = 150;

export class InfoManager {
  container?: Container;
  stakeText?: Text;
  labelText!: Text;
  rect!: Container;
  width = 0;
  private _containerIsMounted: boolean = false;
  private _updateValueOfTextControl!: (value?: number) => void;

  constructor(private game: Dede) {
    this.game = game;
  }

  updateValues() {
    if (!this._containerIsMounted) return;

    this._updateValueOfTextControl(
      this.game.anteBetActive ? this.game.stake * ANTEBET_MULTIPLIER : this.game.stake
    );
  }

  private _handleResize = () => {
    const orientation = this.game.getOrientation();
    this.rect.scale.set(this.game.scale);
    if (orientation === 'portrait') {
      this.rect.y = this.game.height - 20 * this.game.scale;
      this.rect.x =
        this.game.width / 2 - (this.labelText.width + 60 + MAX_VALUE_WITH) * this.game.scale;
    } else {
      // this.rect.y = this.game.height - 60 * this.game.scale;
      this.rect.y =
        this.game.dynamicGameContainer.y +
        this.game.dynamicGameContainer.height -
        1280 * this.game.scale;
      this.rect.x = 993 * this.game.scale + this.game.dynamicGameContainer.x;
    }
  };

  mount(parentContainer: Container) {
    if (this._containerIsMounted) return;

    const { updateValue, container, labelText } = LayoutManager.createBalanceStyleText(
      parentContainer,
      {
        x: 0,
        y: 0,
        label: getLocale('slots.ui.common.bet').toLocaleUpperCase(),
      }
    );
    this.labelText = labelText;
    const handleOrientationChange = () => {
      const orientation = this.game.getOrientation();
      if (orientation === 'portrait') {
        this._updateValueOfTextControl = (value?: number) =>
          updateValue(
            this.game.width / 2 - (labelText.x + labelText.width + 40) * this.game.scale,
            value
          );
      } else {
        this._updateValueOfTextControl = (value?: number) =>
          updateValue(MAX_VALUE_WITH, value, undefined);
      }
    };
    this.rect = container;
    handleOrientationChange();

    this._containerIsMounted = true;

    this.updateValues();

    this.game.onStakeChange.addEventListener(() => {
      this.updateValues();
    });
    this.game.onAnteBetChange.addEventListener(() => {
      this.updateValues();
    });

    this._handleResize();

    this.game.onResize.addEventListener(this._handleResize);
    this.game.onOrientationChange.addEventListener(handleOrientationChange);
  }
}
