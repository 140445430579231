import SoundTrack from '../../../../game/managers/soundManager/soundTrack';
const DEFAULT_VOLUME = 0.7;

class FreeSpinTrack extends SoundTrack {
  private _isPlaying = false;

  mount() {
    this.registerMusic('musFreeSpinsIntro', { maxVolume: DEFAULT_VOLUME });
    this.registerMusic('musFreeSpinsOutro', { maxVolume: DEFAULT_VOLUME });
    this.registerMusic('musFreeSpins', { loop: true, maxVolume: DEFAULT_VOLUME });

    this._isMounted = true;
  }

  play() {
    if (this._isPlaying)
      return;
    this._isPlaying = true;

    const intro = this.getSound('musFreeSpinsIntro')!;
    intro.play();
    intro.onComplete(() => {
      const freeSpins = this.getSound('musFreeSpins')!;
      freeSpins.play();
      freeSpins.onComplete(() => this._handleComplete());
    });
  }

  stop() {
    if (!this._isPlaying)
      return;
    this._isPlaying = false;

    const outro = this.getSound('musFreeSpinsOutro')!;
    const freeSpins = this.getSound('musFreeSpins')!;

    this.crossFade([freeSpins], outro, { stopAndRestoreVolume: true });
    outro.play();
    outro.onComplete(() => this._handleComplete());
  }
}

export default FreeSpinTrack;
