import SoundTrack from '../../../../game/managers/soundManager/soundTrack';
import JackpotMiniTrack from './jackpotMiniTrack';
import JackpotMinorTrack from './jackpotMinorTrack';
import JackpotMajorTrack from './jackpotMajorTrack';
import JackpotGrandTrack from './jackpotGrandTrack';
import { JackpotTier } from '../../JackpotManager';

class JackpotMusicTrack extends SoundTrack {
  private _jackpotMiniTrack?: JackpotMiniTrack;
  private _jackpotMinorTrack?: JackpotMinorTrack;
  private _jackpotMajorTrack?: JackpotMajorTrack;
  private _jackpotGrandTrack?: JackpotGrandTrack;
  private _generalGameplayMusicTrack!: SoundTrack;

  mount(generalGameplayMusicTrack: SoundTrack) {
    this._jackpotMiniTrack = new JackpotMiniTrack('jackpotMiniTrack');
    this._jackpotMiniTrack?.mount();
    this.registerTrack('jackpotMiniTrack', this._jackpotMiniTrack);

    this._jackpotMinorTrack = new JackpotMinorTrack('jackpotMinorTrack');
    this._jackpotMinorTrack?.mount();
    this.registerTrack('jackpotMinorTrack', this._jackpotMinorTrack);

    this._jackpotMajorTrack = new JackpotMajorTrack('jackpotMajorTrack');
    this._jackpotMajorTrack?.mount();
    this.registerTrack('jackpotMajorTrack', this._jackpotMajorTrack);

    this._jackpotGrandTrack = new JackpotGrandTrack('jackpotGrandTrack');
    this._jackpotGrandTrack?.mount();
    this.registerTrack('jackpotGrandTrack', this._jackpotGrandTrack);

    this.registerMusic('musBaseGameWinMode', { loop: true, maxVolume: 0.5 });

    // This register is here, otherwise the jackpotTracks crossfade won't know about it.
    this.registerTrack('generalGameplayMusicTrack', generalGameplayMusicTrack);
    this._generalGameplayMusicTrack = generalGameplayMusicTrack;
    this._isMounted = true;
  }

  playJackpotMusic(tier: JackpotTier) {
    let track!: SoundTrack;

    switch (tier) {
      case JackpotTier.mini:
        track = this._jackpotMiniTrack!;
        break;
      case JackpotTier.minor:
        track = this._jackpotMinorTrack!;
        break;
      case JackpotTier.major:
        track = this._jackpotMajorTrack!;
        break;
      case JackpotTier.grand:
        track = this._jackpotGrandTrack!;
        break;
      default:
        throw new Error(`unknown jackpot track tier: ${tier}`);
    }
    track.play();

    // @todo: cross fading back and forth sucks.  Should be able to duck the track down to a custom volume instead.
    // (0 in this case)
    // I dont want do do stop and restore, because then it triggers a bunch
    // of unnecassary stop events and other weird stuff.
    this.crossFade([this._generalGameplayMusicTrack], track, {
      //      stopAndRestoreVolume: true,
    });
  }

  stopJackpotMusic() {
    // need to pull the volume back up to 1 before crossfade or crossfade will think that the current volume of 0
    // is the target volume it must fade to.
    this._generalGameplayMusicTrack.volume = 1;
    this.crossFade(
      [
        this._jackpotMiniTrack!,
        this._jackpotMinorTrack!,
        this._jackpotMajorTrack!,
        this._jackpotGrandTrack!,
      ],
      this._generalGameplayMusicTrack,
      { stopAndRestoreVolume: true }
    );
  }
}

export default JackpotMusicTrack;
