import { BitmapText, Container, Sprite, Text, Texture } from 'pixi.js';
import WinHistoryManagerCore from '../../../../game/managers/winHistoryManager';
import { IEventDetails } from '../../../gameEvent';
import {
  TWinHistoryEvent,
  TWinHistoryItem,
} from '../../../../game/managers/winHistoryManager/winHistory.types';
import { formatAsCurrency } from '../../../../game/managers/currencyManager';
import accamaxDebug, { getDebugName } from '../../../../debug';
import {
  simpleAnimatePropertiesTo,
  simpleAnimationDuration,
} from '../../../../game/managers/animationManager';
import { registerLogCategory } from '../../../../debug/privateLogger';
import {
  changeComponentVisibility,
  textWidthLimiter,
} from '../../../../layoutTools/pixiComponentManagement';
import ScrollableContainerInterface, {
  TSnapScrollEvent,
} from '../../../../layoutTools/scrollableContainerInterface';
import SoundManager from '../soundManager';
import { getLocale } from '../../../../../localization';
import { IGameOutcome } from '../../service/types';
import { Dede } from '../..';
import { fontSafeString } from '../../resources/fonts/fonts';
import { vec2 } from 'gl-matrix';
import { getLocalCoordsFor } from '../../../../game/math/layoutUtils';
import { getDualAxisLength } from '../../../../game/math/mathUtils';
import Popup from '../../models/popup';
import { splitSentence } from '../../../../utils/text';
import { ANTEBET_MULTIPLIER } from '../../resources/constants';

const log = registerLogCategory('WinHistoryManager');

const verticalElementsAllowed = 4;
const horizontalElementsAllowed = 1;
const winHistoryWidthLimit = 320;
const winningsBarHeight = 204;

const winHistoryFontSize = 38;
const textOffset = -2.5;

const dividerWidth = 3;
const dividerHeight = 60;
const symbolCountPadLeft = 25;
const symbolCountTextWidth = 25;
const symbolSpaceLeft = 10;
const symbolWidth = 45;
const symbolHeight = 45;
const winAmountPadRight = 18;

const historyXOffset = -165;
const historyYOffset = 150;

const mainHeadingFontSize = 45;
const subHeadingFontSize = 30;
const tumbleWinValueXOffset = 315;
const tumbleWinValueYOffset = 26;
const tumbleWinSmallValueYOffset = 18;
const totalWinValueXOffset = 315;
const totalWinValueYOffset = 45;
const subValueFontSize = 30;
const mainValueFontSize = 45;
const totalWinXOffset = 25;

const totalWinFadeInDuration = 300;
const totalWinFadeOutDuration = 300;

const historyBackgroundNormalScale = 0.9;
const historyBackgroundFreeSpinScale = 1.35;
const backgroundSpriteXOffset = 19;

class WinHistoryManager extends WinHistoryManagerCore {
  private _historyCells: Container[];
  private _historyValueElements: Text[];
  private _historySymbolElements: Sprite[];
  private _historyDividers: Sprite[];
  private _historySymbolCountElements: Text[];
  private _symbolTextures!: Texture[];
  private _soundManager!: SoundManager;
  private _isMounted = false;
  private _tumbleWinCurrentValue = 0;
  private _container!: Container;
  private _tumbleWinLabel!: Text;
  private _totalWinLabel!: Text;
  private _tumbleWinValueText!: BitmapText;
  private _tumbleMultiplierValueText!: BitmapText;
  private _totalWinValueText!: BitmapText;
  private _tumbleMultiplierValue = 0;
  private _appWinHistoryContainer!: Container;
  private _appTotalWinContainer!: Container;
  private _backgroundSprite!: Sprite;
  private _winHistoryScrollingInterface!: ScrollableContainerInterface;
  private _totalFSWinValue = 0;
  private _freeSpinsActive = false;
  private _freeSpinAggrMultiplier = 0;

  constructor(private game: Dede, soundManager: SoundManager) {
    log(1)('constructor', { gameStatsInterface: game });
    super();

    this._historyCells = [];
    this._historyValueElements = [];
    this._historySymbolElements = [];
    this._historySymbolCountElements = [];
    this._historyDividers = [];
    this._soundManager = soundManager;

    accamaxDebug.debug.addFakeWinHistoryItem = () => {
      this._addWinHistoryItem(
        Math.floor(Math.random() * 10),
        Math.pow(Math.random() * 2.5 + 0.8, 5),
        Math.floor(Math.pow(Math.random() * 2.5, 2)) + 8
      );
    };
  }

  mount(winHistoryContainer: Container, totalWinContainer: Container) {
    log(1)('mount', {
      winHistoryContainer,
      totalWinContainer,
      gameStatsInterface: this.game,
    });

    this._appWinHistoryContainer = winHistoryContainer;
    this._appTotalWinContainer = totalWinContainer;
    this._historyCells = [];
    this._historyDividers = [];
    this._historyValueElements = [];
    this._historySymbolElements = [];
    this._historySymbolCountElements = [];

    const separatorSprite = new Sprite(Texture.from('tumbleWinSeparator'));
    this._backgroundSprite = new Sprite(Texture.from('tumbleWinContainer'));
    this._backgroundSprite.visible = false;
    this._backgroundSprite.x = backgroundSpriteXOffset;
    this._backgroundSprite.scale.set(1, historyBackgroundNormalScale);
    separatorSprite.x = this._backgroundSprite.width / 2;
    separatorSprite.scale.set(1, 0.7);
    this._backgroundSprite.addChild(separatorSprite);
    this._appTotalWinContainer.addChild(this._backgroundSprite);

    const cellWidth = winHistoryWidthLimit / horizontalElementsAllowed;

    for (let i = 1; i < horizontalElementsAllowed; i++) {
      // const dividerSprite = new Sprite(Texture.from('winHistoryDivider'));
      const dividerSprite = new Sprite();
      this._appWinHistoryContainer.addChild(dividerSprite);
      this._historyDividers.push(dividerSprite);
      dividerSprite.anchor.set(0.5);
      dividerSprite.x = i * cellWidth;
      dividerSprite.y = winningsBarHeight / 2;
      dividerSprite.height = dividerHeight;
      dividerSprite.width = dividerWidth;
      dividerSprite.alpha = 0;
    }

    this._symbolTextures = [
      Texture.from('s1'),
      Texture.from('s2'),
      Texture.from('s3'),
      Texture.from('s4'),
      Texture.from('s5'),
      Texture.from('s6'),
      Texture.from('s7'),
      Texture.from('s8'),
      Texture.from('s9'),
      Texture.from('s13'),
    ];

    this._winHistoryScrollingInterface = new ScrollableContainerInterface(
      this._appWinHistoryContainer,
      {
        activeRegion: {
          x: 0,
          y: 0,
          width: winHistoryWidthLimit,
          height: winningsBarHeight,
        },
      }
    );

    this._winHistoryScrollingInterface.onSnapScroll.addEventListener(
      (event, { newIndexX }: TSnapScrollEvent) => {
        log(2)('onSnapScroll', { newIndexX });
        this._soundManager.soundEffectsTrack?.playClick();
        this._updateWinHistoryCellPositions(newIndexX);
      }
    );

    this._winHistoryScrollingInterface.enableSnapScrolling({
      dragXSnapScrollThreshold: winHistoryWidthLimit / horizontalElementsAllowed / 1.5,
      snapScrollXRange: 1,
      snapScrollYRange: 1,
      wheelChangesWhichAxis: 'x',
    });

    this._deleteAllHistoryCells();

    this.game.freeSpinManager.onFreeSpinTransition.addEventListener((event, isActive) => {
      this._handleFreeSpinModeChanged(event, isActive);
    });

    const historyText = new Text();
    this._appWinHistoryContainer.addChild(historyText);
    historyText.anchor.set(0.5);
    historyText.x = 0;
    historyText.y = 120;
    historyText.text = getLocale('slots.ui.common.history').toUpperCase();
    historyText.style = {
      fill: 0xffffff,
      fontSize: 40,
      fontFamily: 'ManchoBold',
    };
  }

  get tumbleWinAmountFocalPoint() {
    const tumbleWinTranslated = getLocalCoordsFor(this._tumbleWinValueText);

    return vec2.fromValues(
      this._tumbleWinCurrentValue
        ? tumbleWinTranslated.pos[0]
        + (this._tumbleWinValueText.width / 2) * tumbleWinTranslated.scale[0]
        : tumbleWinTranslated.pos[0]
          + (this._freeSpinsActive ? 50 : 100) * tumbleWinTranslated.scale[0],
      tumbleWinTranslated.pos[1]
      + this._tumbleWinValueText.height / 2
      + (this._freeSpinsActive ? 10 : 0)
    );
  }

  get tumbleMultiplierAmountFocalPoint() {
    const tumbleMultiplierTranslated = getLocalCoordsFor(
      this._tumbleMultiplierValueText,
      undefined,
      true
    );

    return vec2.fromValues(
      tumbleMultiplierTranslated.pos[0] + (this._freeSpinsActive ? 30 : 0),
      tumbleMultiplierTranslated.pos[1] + (this._freeSpinsActive ? -15 : -30)
    );
  }

  private async _deleteAllHistoryCells() {
    log(2)('_deleteAllHistoryCells', { historyCells: this._historyCells });
    if (!this._historyCells) return;

    // await performFadeOutAnimation(this._appWinHistoryContainer, winHistoryElementFadeOutDuration);

    this._historyCells.forEach((container) => {
      if (container.parent) container.parent.removeChild(container);

      container.destroy();
    });

    this._historyDividers.forEach((historyDivider) => {
      historyDivider.alpha = 0;
    });

    this._historyCells = [];
    this._historyValueElements = [];
    this._historySymbolElements = [];
    this._historySymbolCountElements = [];

    this._winHistoryScrollingInterface.updateSnapScrollRange({
      snapScrollXRange: 1,
      snapScrollYRange: 1,
    });
  }

  private async _addWinHistoryItem(symbolIndex: number, winValue: number, symbolCount: number) {
    // await performFadeOutAnimation(this._appWinHistoryContainer, winHistoryElementFadeOutDuration);

    this._historyCells.push(this._createCell(symbolIndex, winValue, symbolCount));

    this._updateWinHistoryCellPositions();

    this._winHistoryScrollingInterface.updateSnapScrollRange({
      snapScrollXRange: Math.max(
        1,
        Math.ceil(this._historyCells.length / verticalElementsAllowed)
        - horizontalElementsAllowed
        + 1
      ),
      snapScrollYRange: 1,
    });

    // return performFadeInAnimation(this._appWinHistoryContainer, winHistoryElementFadeInDuration);
  }

  private _updateWinHistoryCellPositions(hOffset = 0) {
    log(3)('_updateWinHistoryCellPositions', {
      hOffset,
      historyCells: this._historyCells,
      historyDividers: this._historyDividers,
      winHistoryWidthLimit,
      horizontalElementsAllowed,
      verticalElementsAllowed,
    });

    const cellWidth = winHistoryWidthLimit / horizontalElementsAllowed;
    const cellHeight = winningsBarHeight / verticalElementsAllowed;
    const visibleCellsCount = horizontalElementsAllowed * verticalElementsAllowed;
    const visibleStart = hOffset * 2;

    this._historyCells.forEach((historyCell, ind) => {
      const visibleIndex = this._historyCells.length - ind - 1 - visibleStart;
      const hPosition = Math.floor(visibleIndex / verticalElementsAllowed);
      const vPosition = visibleIndex % verticalElementsAllowed;
      log(4)('updateWinHistoryItemPosition', {
        ind,
        visibleIndex,
        hPosition,
        vPosition,
        visibleCellsCount,
        historyCell,
        cellWidth,
        cellHeight,
      });

      if (hPosition > 0 && !vPosition && hPosition < horizontalElementsAllowed)
        this._historyDividers[hPosition - 1].alpha = 1;

      historyCell.x = cellWidth * hPosition + historyXOffset;
      historyCell.y = cellHeight * (vPosition + 0.5) + historyYOffset;
      changeComponentVisibility(
        this._appWinHistoryContainer,
        historyCell,
        visibleIndex < visibleCellsCount && visibleIndex >= 0
      );
    });
  }

  private _createCell(symbolIndex: number, winValue: number, symbolCount: number) {
    log(3)('_createCell', {
      symbolIndex,
      winValue,
      symbolCount,
      historyCells: this._historyCells,
      historySymbolCountElements: this._historySymbolCountElements,
      gameStatsInterface: this.game,
      symbolTextures: this._symbolTextures,
      historySymbolElements: this._historySymbolElements,
      historyValueElements: this._historyValueElements,
      historyDividers: this._historyDividers,
    });

    const container = new Container();
    this._appWinHistoryContainer.addChild(container);

    const symbolCountText = new Text();
    container.addChild(symbolCountText);
    this._historySymbolCountElements.push(symbolCountText);
    symbolCountText.anchor.set(0, 0.5);
    symbolCountText.x = symbolCountPadLeft;
    symbolCountText.y = textOffset;
    symbolCountText.text = symbolCount;
    symbolCountText.style = {
      fill: 0xffffff,
      fontSize: winHistoryFontSize,
      fontFamily: 'ManchoBold',
    };

    const symbolSprite = new Sprite(this._symbolTextures[symbolIndex]);
    container.addChild(symbolSprite);
    this._historySymbolElements.push(symbolSprite);
    symbolSprite.anchor.set(0, 0.5);
    symbolSprite.x = symbolCountPadLeft + symbolCountTextWidth + symbolSpaceLeft;
    symbolSprite.y = 0;
    symbolSprite.width = symbolWidth;
    symbolSprite.height = symbolHeight;

    const winAmountText = new Text();
    container.addChild(winAmountText);
    this._historyValueElements.push(winAmountText);
    winAmountText.anchor.set(1, 0.5);
    winAmountText.text = formatAsCurrency(winValue);
    winAmountText.style = {
      fill: 0xfe9b1c,
      fontSize: winHistoryFontSize,
      fontFamily: 'ManchoBold',
    };
    winAmountText.x = winHistoryWidthLimit / horizontalElementsAllowed - winAmountPadRight;
    winAmountText.y = textOffset;

    return container;
  }

  _handleFreeSpinModeChanged(event: IEventDetails, isActive: boolean) {
    log(2)('_handleFreeSpinModeChanged', { isActive });
    this._freeSpinsActive = isActive;
  }

  private _updateHeadingTexts() {
    if (this.game.freeSpinSpinsStarted) {
      this._tumbleWinLabel.text = getLocale('slots.ui.common.tumbleWin').toUpperCase();
    }
    else {
      this._tumbleWinLabel.text = getLocale('slots.ui.common.win').toUpperCase();
    }
    this._tumbleWinLabel.x = tumbleWinValueXOffset - this._tumbleWinLabel.width - 30;
  }

  private _createHeadingTexts = () => {
    const tumbleWinLabel = new Text();
    this._container.addChild(tumbleWinLabel);
    tumbleWinLabel.style = {
      fill: 0xffffff,
      fontSize: subHeadingFontSize,
      fontFamily: 'ManchoBold',
      dropShadow: true,
    };
    tumbleWinLabel.alpha = 0;
    this._tumbleWinLabel = tumbleWinLabel;
    this._updateHeadingTexts();

    const totalWinLabel = new Text();
    this._container.addChild(totalWinLabel);
    totalWinLabel.text = getLocale('slots.ui.common.win').toUpperCase();
    totalWinLabel.style = {
      fill: 0xffffff,
      fontSize: mainHeadingFontSize,
      fontFamily: 'ManchoBold',
      dropShadow: true,
    };
    totalWinLabel.x = totalWinValueXOffset - totalWinLabel.width - 30;
    totalWinLabel.y = totalWinValueYOffset;
    totalWinLabel.alpha = 0;
    this._totalWinLabel = totalWinLabel;
  };

  private _createContainer() {
    log(1)('_createTotalWinContainer');

    this._container = new Container();
    this._appTotalWinContainer.addChild(this._container);
    this._isMounted = true;
    this._container.x = totalWinXOffset;

    accamaxDebug.debug.totalWinDebug = {};

    // @ts-ignore
    accamaxDebug.debug.totalWinDebug.fullRestore = () => {
      this._container.parent.removeChild(this._container);
      this._createContainer();
    };

    const tumbleWinValueText = new BitmapText({
      text: fontSafeString('goldenTextFontOld', ' '),
      style: {
        fontFamily: 'goldenTextFontOld',
        fontSize: subValueFontSize,
      },
    });

    this._container.addChild(tumbleWinValueText);
    this._tumbleWinValueText = tumbleWinValueText;
    tumbleWinValueText.text = '';

    tumbleWinValueText.y = tumbleWinValueYOffset;
    tumbleWinValueText.x = tumbleWinValueXOffset;
    tumbleWinValueText.alpha = 0;
    tumbleWinValueText.anchor.set(0, 0.5);

    const tumbleMultiplierValueText = new BitmapText({
      text: fontSafeString('symbolOverlayFontOld', ' '),
      style: {
        fontFamily: 'symbolOverlayFontOld',
        fontSize: subValueFontSize,
      },
    });

    this._container.addChild(tumbleMultiplierValueText);
    tumbleMultiplierValueText.text = '';
    tumbleMultiplierValueText.y = this._freeSpinsActive
      ? tumbleWinSmallValueYOffset
      : tumbleWinValueYOffset;
    tumbleMultiplierValueText.x = tumbleWinValueXOffset;
    tumbleMultiplierValueText.anchor.set(0, 0.5);
    this._tumbleMultiplierValueText = tumbleMultiplierValueText;

    const totalWinValueText = new BitmapText({
      text: fontSafeString('goldenTextFontOld', ' '),
      style: {
        fontFamily: 'goldenTextFontOld',
        fontSize: mainValueFontSize,
      },
    });

    this._container.addChild(totalWinValueText);
    this._totalWinValueText = totalWinValueText;
    totalWinValueText.text = '';
    totalWinValueText.y = totalWinValueYOffset + 26;
    totalWinValueText.x = totalWinValueXOffset;
    totalWinValueText.alpha = 0;
    totalWinValueText.anchor.set(0, 0.5);

    this._createHeadingTexts();

    this._container.y = 10;
  }

  get hasCurrentTumbleValue() {
    return !!this._tumbleWinCurrentValue;
  }

  public addToTumbleMultiplierValue(value: number) {
    this._tumbleMultiplierValue += value;
    this._tumbleMultiplierValueText.text = fontSafeString(
      'symbolOverlayFontOld',
      `x${this._tumbleMultiplierValue}`
    );
  }

  private _checkForMaxWin() {
    const maxWin
      = this.game.config.limits.winCap
      * (this.game.anteBetActive ? this.game.stake * ANTEBET_MULTIPLIER : this.game.stake);
    if (this._tumbleWinCurrentValue >= maxWin || this._totalFSWinValue >= maxWin) {
      if (this._tumbleWinCurrentValue >= maxWin) this._tumbleWinCurrentValue = maxWin;
      if (this._totalFSWinValue >= maxWin) this._totalFSWinValue = maxWin;
    }
  }

  private _applyTextWidthLimit(text: BitmapText, maxWidth: number, value: number) {
    const newSafeString = fontSafeString('goldenTextFontOld', formatAsCurrency(value));
    const scale = textWidthLimiter(text, maxWidth, newSafeString);
    text.scale.set(scale);
    text.text = newSafeString;
  }

  public updateTumbleWinValue(value: number) {
    log(4)('perform value transition animation');
    if (!this.game.freeSpinSpinsStarted)
      this._backgroundSprite.scale.set(1, historyBackgroundNormalScale);
    this._backgroundSprite.visible = true;

    this._updateHeadingTexts();
    const isFirstValue = !this._tumbleWinCurrentValue;

    this._tumbleWinCurrentValue += value;

    this._applyTextWidthLimit(this._tumbleWinValueText, 150, this._tumbleWinCurrentValue);

    this._tumbleMultiplierValueText.x
      = this._tumbleWinValueText.x + this._tumbleWinValueText.width + 10;

    if (isFirstValue) {
      log(4)('perform master fade in');
      simpleAnimatePropertiesTo(
        totalWinFadeInDuration,
        this._tumbleWinLabel,
        this._tumbleWinLabel,
        { alpha: { endValue: 1 } },
        { debugName: getDebugName('winHistoryManager-_tumbleWinLabelFadeIn') }
      );

      simpleAnimatePropertiesTo(
        totalWinFadeInDuration,
        this._tumbleWinValueText,
        this._tumbleWinValueText,
        { alpha: { endValue: 1 } },
        { debugName: getDebugName('winHistoryManager-_tumbleWinValueTextFadeIn') }
      );
    }
  }

  public updateTotalWinValue(outcome: IGameOutcome) {
    if (this.game.freeSpinSpinsStarted)
      this._backgroundSprite.scale.set(1, historyBackgroundFreeSpinScale);

    const isLastOutcome = outcome.isLastOutcome;
    const isFirstValue = !this._totalFSWinValue;
    // this._totalFSWinValue += value * (withMultiplier ? this._freeSpinAggrMultiplier || 1 : 1);
    this._totalFSWinValue = isLastOutcome
      ? outcome.spinData?.runningTotal!
      : outcome.prevSpinData?.runningTotal! || 0;
    this._checkForMaxWin();

    this._applyTextWidthLimit(this._totalWinValueText, 150, this._totalFSWinValue);

    if (isFirstValue) {
      simpleAnimatePropertiesTo(
        totalWinFadeInDuration,
        this._totalWinLabel,
        this._totalWinLabel,
        { alpha: { endValue: 1 } },
        { debugName: getDebugName('winHistoryManager-totalWinLabelFadeIn') }
      );

      simpleAnimatePropertiesTo(
        totalWinFadeInDuration,
        this._totalWinValueText,
        this._totalWinValueText,
        { alpha: { endValue: 1 } },
        { debugName: getDebugName('winHistoryManager-totalWinValueFadeIn') }
      );
    }
  }

  async clearTumbleWinTexts() {
    if (!this._appTotalWinContainer) return;

    if (!this._isMounted) {
      this._createContainer();
      this._isMounted = true;
    }

    if (!this._freeSpinsActive && this._totalWinValueText.alpha === 1) {
      simpleAnimatePropertiesTo(
        totalWinFadeInDuration,
        this._totalWinLabel,
        this._totalWinLabel,
        { alpha: { endValue: 0 } },
        { debugName: getDebugName('winHistoryManager-totalWinLabelFadeIn') }
      );

      simpleAnimatePropertiesTo(
        totalWinFadeInDuration,
        this._totalWinValueText,
        this._totalWinValueText,
        { alpha: { endValue: 0 } },
        { debugName: getDebugName('winHistoryManager-totalWinValueFadeIn') }
      );

      this._totalFSWinValue = 0;
      this._freeSpinAggrMultiplier = 0;
      this._backgroundSprite.visible = false;
    }

    if (!this._totalWinValueText.alpha) {
      this._backgroundSprite.visible = false;
    }

    simpleAnimatePropertiesTo(
      totalWinFadeOutDuration,
      this._tumbleWinLabel,
      this._tumbleWinLabel,
      { alpha: { endValue: 0 } },
      { debugName: getDebugName('winHistoryManager-_tumbleWinLabelFadeOut') }
    );

    await simpleAnimatePropertiesTo(
      totalWinFadeOutDuration,
      this._tumbleWinValueText,
      this._tumbleWinValueText,
      { alpha: { endValue: 0 } },
      { debugName: getDebugName('winHistoryManager-_tumbleWinValueTextFadeOut') }
    ).promise.then(() => {
      this._tumbleWinValueText.text = '';
      this._tumbleWinCurrentValue = 0;
    });

    this._tumbleMultiplierValue = 0;
    this._checkWinLabelSizes();
  }

  private _checkWinLabelSizes() {
    this._tumbleWinLabel.style.fontSize = this._freeSpinsActive
      ? subHeadingFontSize
      : mainHeadingFontSize;
    this._tumbleWinLabel.x = tumbleWinValueXOffset - this._tumbleWinLabel.width - 30;
    this._tumbleWinValueText.style.fontSize = this._freeSpinsActive
      ? subValueFontSize
      : mainValueFontSize;
    this._tumbleWinValueText.y = this._freeSpinsActive
      ? tumbleWinSmallValueYOffset
      : tumbleWinValueYOffset;
    this._tumbleMultiplierValueText.style.fontSize = this._freeSpinsActive
      ? subValueFontSize
      : mainValueFontSize;
  }

  async flyMultiplierTexts(
    multiplierTexts: { text: BitmapText; value: number }[],
    outcome: IGameOutcome
  ) {
    if (!this._tumbleWinCurrentValue) return false;

    // Prepare promises to move symbol multipliers to tumble multiplier win line text or FS aggregate
    const multiplierFlightPromises: Promise<void>[] = [];
    multiplierTexts.forEach(({ text, value }) => {
      const gPos = getLocalCoordsFor(text);
      text.parent?.removeChild(text);
      text.x = gPos.pos[0];
      text.y = gPos.pos[1];
      text.scale.set(gPos.scale[0], gPos.scale[1]);
      this.game.app.stage.addChild(text);

      const dest = this._freeSpinsActive
        ? getLocalCoordsFor(this.game.freeSpinManager.totalMultiplierText).pos
        : this.game.winHistoryManager.tumbleMultiplierAmountFocalPoint;
      const dist = getDualAxisLength(text.x - dest[0], text.y - dest[1]);

      multiplierFlightPromises.push(
        simpleAnimatePropertiesTo(
          dist / (this.game.isQuickMode ? 4 : 2),
          text as unknown as { [p: string]: number },
          text as unknown as { [p: string]: number },
          {
            x: { endValue: dest[0] },
            y: { endValue: dest[1] },
          },
          { autoEndOnError: true }
        )
          .promise.then(async () => {
            if (this._freeSpinsActive) {
              this._freeSpinAggrMultiplier = outcome.isLastOutcome
                ? outcome.spinData?.aggregatedMultiplier!
                : (outcome.prevSpinData?.aggregatedMultiplier! || 0) + outcome.multiplier!;
              this.game.freeSpinManager.totalMultiplierText.text = fontSafeString(
                'symbolOverlayFontOld',
                'x' + this._freeSpinAggrMultiplier
              );
            }
            else this.game.winHistoryManager.addToTumbleMultiplierValue(value);

            const startingScale = text.scale.x;

            await simpleAnimationDuration(
              this.game.isQuickMode ? 50 : 100,
              ({ progress }: { progress: number }) => {
                text.alpha = 1 - progress;
                text.scale.set(startingScale * progress * 0.5 + startingScale);
              }
            ).promise;
          })
          .then(() => {
            text.parent?.removeChild(text);
            text.destroy();
          })
      );
    });

    // Wait for symbol multiplier moves if any

    if (multiplierFlightPromises.length) {
      this.game.freeSpinManager.animateWin();
      await Promise.all(multiplierFlightPromises);
      await new Promise<void>((resolve) => setTimeout(resolve, this.game.isQuickMode ? 100 : 300));
    }

    if (multiplierFlightPromises.length) {
      let source: BitmapText;
      let dest;
      let dist;
      let startingScale: number;

      // If FS is active, we need to copy the FS agg multiplier text, so that we have something to move
      // and move it to the tumble multiplier win line text
      if (this._freeSpinsActive) {
        source = new BitmapText({
          text: fontSafeString(
            'symbolOverlayFontOld',
            this.game.freeSpinManager.totalMultiplierText.text
          ),
          style: {
            fontFamily: 'symbolOverlayFontOld',
            fontSize: 300,
          },
        });

        this.game.app.stage.addChild(source);
        const loc = getLocalCoordsFor(this.game.freeSpinManager.totalMultiplierText);
        source.x = loc.pos[0];
        source.y = loc.pos[1];
        source.zIndex = 100;
        source.scale.set(loc.scale[0], loc.scale[1]);
        source.anchor.set(0.5);

        const dest = this.game.winHistoryManager.tumbleMultiplierAmountFocalPoint;
        dist = getDualAxisLength(source.x - dest[0], source.y - dest[1]);

        startingScale = source.scale.x;

        await simpleAnimatePropertiesTo(
          dist / (this.game.isQuickMode ? 2 : 1),
          source as unknown as { [p: string]: number },
          source as unknown as { [p: string]: number },
          {
            x: { endValue: dest[0] },
            y: { endValue: dest[1] },
          },
          { autoEndOnError: true }
        )
          .promise.then(async () => {
            this.game.winHistoryManager.addToTumbleMultiplierValue(this._freeSpinAggrMultiplier);

            await simpleAnimationDuration(
              this.game.isQuickMode ? 50 : 100,
              ({ progress }: { progress: number }) => {
                source.alpha = 1 - progress;
                source.scale.set(startingScale * progress * 0.5 + startingScale);
              }
            ).promise;

            await new Promise<void>((resolve) => {
              setTimeout(resolve, this.game.isQuickMode ? 150 : 300);
            });
          })
          .then(() => {
            source.parent.removeChild(source);
            source.destroy();
          });
      }

      // Now move tumble multiplier into tumble win amount

      source = this._tumbleMultiplierValueText;
      dest = this._tumbleWinValueText;
      dist = getDualAxisLength(source.x - dest.x, source.y - dest.y);

      startingScale = source.scale.x;

      await simpleAnimatePropertiesTo(
        dist / (this.game.isQuickMode ? 1 : 0.5),
        source as unknown as { [p: string]: number },
        source as unknown as { [p: string]: number },
        {
          x: { endValue: dest.x },
          y: { endValue: dest.y },
        },
        { autoEndOnError: true }
      )
        .promise.then(async () => {
          this._tumbleWinCurrentValue = outcome.spinData?.winAmount! || 0;
          this._checkForMaxWin();

          this._applyTextWidthLimit(this._tumbleWinValueText, 150, this._tumbleWinCurrentValue);

          if (this._freeSpinsActive) this.updateTotalWinValue(outcome);

          await simpleAnimationDuration(
            this.game.isQuickMode ? 50 : 100,
            ({ progress }: { progress: number }) => {
              source.alpha = 1 - progress;
              source.scale.set(startingScale * progress * 0.5 + startingScale);
            }
          ).promise;
        })
        .then(() => {
          source.text = '';
          source.scale.set(startingScale);
          source.alpha = 1;
        });
    }
    // Freespin, but there is no multiplier of any kind, just add the tumble win straight
    else if (this._freeSpinsActive) {
      this.updateTotalWinValue(outcome);
      return false;
    }
  }

  protected _handleWinHistoryChanged(
    event: IEventDetails,
    { winHistoryItem, winHistoryEventType }: TWinHistoryEvent
  ) {
    log(2)('_handleWinHistoryChanged', {
      winHistoryItem,
      winHistoryEventType,
      historyCells: this._historyCells,
    });

    switch (winHistoryEventType) {
      case 'clear':
        this._deleteAllHistoryCells();

        this.clearTumbleWinTexts();
        break;

      case 'add':
        const _outcome = (winHistoryItem as TWinHistoryItem).outcome as IGameOutcome;
        _outcome.winnings.forEach(({ winAmount, winningSymbol, symbols }) => {
          if (winningSymbol === this.game.config.scatterSymbol) {
            // last texture is scatter
            winningSymbol = this._symbolTextures.length - 1;
          }
          this._addWinHistoryItem(winningSymbol, winAmount, symbols.length);
        });
        break;

      default:
        throw new Error('Unsupported event type for winHistoryEventType.');
    }
  }
}

export default WinHistoryManager;
