import { ConfigProvider, Flex, Modal, Switch, Table, Tabs, theme } from 'antd';
import React, { memo, useEffect, useState } from 'react';
import { Game } from '../../lib/games/game';
import { Assets } from 'pixi.js';
import { REELS_COUNT, SYMBOLS_PER_REEL } from '../../lib/games/dede/resources/constants';
import styles from './index.module.css';
import { IEventDetails } from '../../lib/games/gameEvent';
import { getLocale } from '../../localization';
import SliderButton from '../sliderButton';
import { formatAsCurrency } from '../../lib/game/managers/currencyManager';
import CachedSettingsManager from '../../lib/cachedSettingsManager';
import { TSoundControlState } from '../../lib/games/dede/managers/soundManager';
import GameHistory from './GameHistory';

const InfoScreen: React.FC<{ game: Game }> = ({ game }) => {
  const [soundState, setSoundState] = useState(CachedSettingsManager.get('soundControlState'));
  const [stake, setStake] = useState(game.stake);
  const [visible, setVisible] = useState(false);
  const [historyKey, setHistoryKey] = useState('');
  const [orientation, setOrientation] = useState(game.getOrientation());
  const [selectedTab, setSelectedTab] = useState('payTable');
  const [gameHeight, setGameHeight] = useState(window.document.body.clientHeight);
  const { soundEffectsEnabled, musicEnabled, gameSoundEnabled }
    = soundState as unknown as TSoundControlState;

  useEffect(() => {
    if (selectedTab === 'history') setHistoryKey(Math.random().toString());
  }, [selectedTab]);

  useEffect(() => {
    const stakeListener = (event: IEventDetails, stake: number) => {
      setStake(stake);
    };
    game.onStakeChange.addEventListener(stakeListener);

    const soundControlStateListener = (value: unknown) => {
      setSoundState(value);
    };
    CachedSettingsManager.addListener('soundControlState', soundControlStateListener);

    const infoListener = () => {
      setSelectedTab('gameInfo');
      setVisible(true);
    };
    game.onInfoButtonClick.addEventListener(infoListener);
    const settingsListener = () => {
      setSelectedTab('settings');
      setVisible(true);
    };
    game.onSettingsButtonClick.addEventListener(settingsListener);

    const handleOrientationChange = (
      event: IEventDetails,
      newOrientation: 'landscape' | 'portrait',
    ) => {
      setOrientation(newOrientation);
    };
    game.onOrientationChange.addEventListener(handleOrientationChange);

    window.addEventListener('resize', () => {
      setGameHeight(document.body.clientHeight);
    });

    return () => {
      game.onStakeChange.removeEventListener(stakeListener);
      CachedSettingsManager.removeListener('soundControlState', soundControlStateListener);
      game.onOrientationChange.removeEventListener(handleOrientationChange);
    };
  }, [game]);

  const handleVisibleChange = () => {
    const orientation = game.getOrientation();
    const containerDiv = document.getElementsByClassName('ant-modal')[0] as HTMLDivElement;
    if (!containerDiv) {
      setTimeout(() => {
        handleVisibleChange();
      }, 1);
      return;
    }
    if (orientation === 'landscape') {
      containerDiv.style.marginTop = (gameHeight - (gameHeight * 0.4 + 50)) / 2 + 'px';
    }
    else {
      containerDiv.style.marginTop = 'unset';
    }
    setGameHeight(document.body.clientHeight);
  };
  useEffect(() => {
    if (visible) handleVisibleChange();
  }, [visible]);

  const renderPayTableCell = (symbol: number, isScatter?: boolean) => {
    const symbolPayouts = game.config.payTable.symbolPayouts.find((s) => s.symbol === symbol);
    if (!symbolPayouts) return null;
    const payoutArray = Object.entries(symbolPayouts.payouts).map(([key, value]) => {
      return { key, value };
    });
    return (
      <div style={{ textAlign: 'center', width: 'fit-content' }} key={symbol}>
        <img
          src={Assets.get('s' + (symbol + 1))?.label}
          style={{ width: 50, height: 50 }}
          alt="symbol"
        />
        <div className={styles.payCellInfo}>
          <div style={{ textAlign: 'center', fontSize: 12 }}>
            {payoutArray.map((payout, i) => (
              <div key={payout.key}>
                {payout.key} {i === payoutArray.length - 1 ? '+ ' : ' '}:
                {formatAsCurrency(
                  Math.round(payout.value * (stake / game.config.limits.betInCoins) * 10000) / 10000,
                )}
              </div>
            ))}
          </div>
          {isScatter && (
            <div style={{ fontSize: 14, textAlign: 'center' }}>
              <div>{getLocale('slots.ui.dede.thisIsScatterSymbol')}</div>
              <div>{getLocale('slots.ui.dede.scatterSymbolIsPresent')}</div>
              <div>{getLocale('slots.ui.dede.scatterPaysOnAnyPosition')}</div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const modalHeight
    = orientation === 'landscape' ? (gameHeight * 0.6 > 400 ? 400 : gameHeight * 0.6) : 400;

  const height = modalHeight - 35;

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
        token: {
          colorPrimary: '#D29737',
          colorBgBase: '#2E2D2DFF',
        },
      }}
    >
      <Modal
        open={visible}
        onCancel={() => setVisible(false)}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        maskClosable={false}
        maskAnimation={false}
        mask={false}
      >
        <div
          style={{
            height: modalHeight,
          }}
        >
          <div className={styles.headerImageContainer}>
            <img src="assets/games/dede/frame_label_decor.webp" />
          </div>
          <div className={styles.leftMarkImageContainer}>
            <img src="assets/games/dede/frame_left.webp" />
          </div>
          <div className={styles.rightMarkImageContainer}>
            <img
              style={{
                width: '41px',
                height: '215px',
              }}
              src="assets/games/dede/frame_left.webp"
            />
          </div>

          <Tabs
            onChange={(key) => {
              setSelectedTab(key);
            }}
            activeKey={selectedTab}
            items={[
              {
                key: 'payTable',
                label: getLocale('slots.ui.common.payTable'),
                children: (
                  <div
                    style={{
                      height: height,
                      gap: '8px',
                      display: 'flex',
                      flexDirection: 'column',
                      textAlign: 'center',
                      overflow: 'auto',
                    }}
                  >
                    <div>
                      <img
                        style={{
                          width: '70px',
                        }}
                        src="assets/games/dede/accamaxLogo.png"
                      />
                    </div>
                    <h1 style={{ margin: '14px 0px 0px 0px' }}>
                      {getLocale('slots.ui.common.gameRules')}
                    </h1>
                    <div>{getLocale('slots.ui.dede.symbolsPayAnywhereOnScreen')}</div>
                    <div>{getLocale('slots.ui.dede.theWinValueIsDeterminedBy')}</div>
                    <div>{getLocale('slots.ui.dede.scatterSymbolIsPresent')}</div>
                    <div>{getLocale('slots.ui.dede.scatterPaysOnAnyPosition')}</div>
                    <div>{getLocale('slots.ui.dede.malfunctionVoidsAllPaysAndPlays')}</div>
                    <div>{getLocale('slots.ui.dede.maximumWinIsXX', '5000x')}</div>
                    <div>{getLocale('slots.ui.dede.overallTheoreticalReturnXX', '95.50%')}</div>
                    <div className={styles.payTableRow}>
                      {Array.from({ length: 9 }, (_, i) => 8 - i).map((symbol) =>
                        renderPayTableCell(symbol))}
                    </div>
                    <div className={styles.payTableRow}>{renderPayTableCell(12, true)}</div>
                  </div>
                ),
              },
              {
                key: 'gameInfo',
                label: getLocale('slots.ui.common.gameInfo').toUpperCase(),
                children: (
                  <div
                    style={{
                      height: height,
                      alignItems: 'center',
                      gap: '8px',
                      display: 'flex',
                      flexDirection: 'column',
                      textAlign: 'center',
                      overflow: 'auto',
                    }}
                  >
                    <div>
                      <img
                        style={{
                          width: '70px',
                        }}
                        src="assets/games/dede/accamaxLogo.png"
                      />
                    </div>
                    <h1 style={{ margin: 0 }}>{getLocale('slots.ui.common.gameInfo')}</h1>
                    <h2 style={{ margin: '14px 0px 0px 0px' }}>
                      {getLocale('slots.ui.dede.tumbleFeature')}
                    </h2>
                    <div>{getLocale('slots.ui.dede.afterEachSpinTheWiningCombination')}</div>
                    <div>{getLocale('slots.ui.dede.tumblingWillContinueUntilNoMoreWinning')}</div>
                    <div>{getLocale('slots.ui.dede.allWinsAreAddToPlayerBalance')}</div>
                    <h2 style={{ margin: '14px 0px 0px 0px' }}>
                      {getLocale('slots.ui.common.multiplierSymbol')}
                    </h2>
                    <div>{getLocale('slots.ui.dede.multiplierSymbolIsPresent')}</div>
                    <div>
                      {getLocale(
                        'slots.ui.dede.whenMultiplierSymbolLands',
                        '2x',
                        '3x',
                        '4x',
                        '5x',
                        '6x',
                        '8x',
                        '10x',
                        '12x',
                        '15x',
                        '20x',
                        '25x',
                        '50x',
                        '100x',
                        '250x',
                        '500x',
                      )}
                    </div>
                    <div>{getLocale('slots.ui.dede.whenTheTumblingFeatureEnds')}</div>
                    <h2 style={{ margin: '14px 0px 0px 0px', fontWeight: 'bold' }}>
                      {getLocale('slots.ui.dede.freeSpinRules')}
                    </h2>
                    <div>{getLocale('slots.ui.dede.freeSpinFeatureIsTriggeredWhen', 4, 15)}</div>
                    <div>{getLocale('slots.ui.dede.duringTheFreeSpinsFeature')}</div>
                    <div>{getLocale('slots.ui.dede.wheneverXXOrMoreScatterSymbols', 3, 5)}</div>
                    <div>{getLocale('slots.ui.dede.specialReelsAreInPlay')}</div>
                    <h2 style={{ margin: '14px 0px 0px 0px' }}>
                      {getLocale('slots.ui.common.extraBet')}
                    </h2>
                    <div>{getLocale('slots.ui.dede.playerHasOptionToIncreaseTheBet', '%25')}</div>
                    <h2 style={{ margin: '14px 0px 0px 0px' }}>
                      {getLocale('slots.ui.common.jackpot')}
                    </h2>
                    <div>{getLocale('slots.ui.dede.jackpotGameIsInitiatedBy', 3)}</div>
                    <div>{getLocale('slots.ui.dede.theSpecialAddOnSymbols')}</div>
                    <div>{getLocale('slots.ui.dede.onceJackpotGameIsTriggered', 15, '5x3')}</div>
                    <div>{getLocale('slots.ui.dede.playersClickOnTheCoinsToReveal')}</div>
                    <div>{getLocale('slots.ui.dede.selectionProcessContinuesUntil', 3)}</div>
                    <h2 style={{ margin: '14px 0px 0px 0px' }}>
                      {getLocale('slots.ui.common.howToPlay')}
                    </h2>
                    <div>{getLocale('slots.ui.dede.onceTheBetIsSelected')}</div>
                    <div>{getLocale('slots.ui.dede.inCaseOfWinTheWinPresentation')}</div>
                    <div>{getLocale('slots.ui.dede.forTheNextRoundOfPlay')}</div>
                    <div>{getLocale('slots.ui.dede.InCaseOfNoWinPlayerLoses')}</div>
                    <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                      <img style={{ width: 20, height: 20 }} src="assets/games/dede/spin.webp" />
                      <div>{getLocale('slots.ui.dede.clickToStartGameAtSelectedBetLevel')}</div>
                    </div>
                    <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                      <img style={{ width: 20, height: 20 }} src="assets/games/dede/+.webp" />
                      <img style={{ width: 20, height: 10 }} src="assets/games/dede/-.webp" />
                      <div>{getLocale('slots.ui.dede.changeBetValueUsing')}</div>
                    </div>
                    <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                      <img
                        style={{ width: 20, height: 20 }}
                        src="assets/games/dede/spin_disabled.webp"
                      />
                      <div>{getLocale('slots.ui.dede.clickToStopReel')}</div>
                    </div>
                    <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                      <img
                        style={{ width: 30, height: 30 }}
                        src="assets/games/dede/autoPlay.webp"
                      />
                      <div>{getLocale('slots.ui.dede.clickToEnableAuto')}</div>
                    </div>
                    <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                      <img style={{ width: 30, height: 30 }} src="assets/games/dede/info.webp" />
                      <div>{getLocale('slots.ui.dede.opensInfoPage')}</div>
                    </div>
                    <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                      <img
                        style={{ width: 20, height: 20 }}
                        src="assets/games/dede/turboSpinPassive.webp"
                      />
                      <div>{getLocale('slots.ui.dede.turboSpinSelectTo')}</div>
                    </div>
                  </div>
                ),
              },
              {
                key: 'settings',
                label: getLocale('slots.ui.common.settings').toUpperCase(),
                children: (
                  <div style={{ height: height, display: 'flex', flexDirection: 'column', gap: 8 }}>
                    <Flex justify="space-between">
                      <div>{getLocale('slots.ui.common.backgroundMusic')}</div>
                      <SliderButton
                        value={musicEnabled}
                        onChange={(checked) => {
                          const newSoundState = {
                            soundEffectsEnabled,
                            gameSoundEnabled,
                            musicEnabled: checked,
                          };
                          newSoundState.gameSoundEnabled
                            = newSoundState.soundEffectsEnabled || checked;
                          CachedSettingsManager.set('soundControlState', newSoundState);
                        }}
                      />
                    </Flex>
                    <Flex justify="space-between">
                      <div>{getLocale('slots.ui.common.soundEffects')}</div>
                      <SliderButton
                        value={soundEffectsEnabled}
                        onChange={(checked) => {
                          const newSoundState = {
                            musicEnabled,
                            gameSoundEnabled,
                            soundEffectsEnabled: checked,
                          };
                          newSoundState.gameSoundEnabled = newSoundState.musicEnabled || checked;
                          CachedSettingsManager.set('soundControlState', newSoundState);
                        }}
                      />
                    </Flex>
                  </div>
                ),
              },
              {
                key: 'history',
                label: getLocale('slots.ui.common.history').toUpperCase(),
                children: <GameHistory game={game} height={height} key={historyKey} />,
              },
            ]}
          />
        </div>
      </Modal>
    </ConfigProvider>
  );
};

export default memo(InfoScreen);
