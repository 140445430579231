export const SYMBOL_SELECTION_TIME = 1000; // ms
export const SYMBOL_GRAVITY = 8;
export const TURBO_SPIN_SYMBOL_GRAVITY = 400;
export const FINISH_INSTANTLY_GRAVITY_SYMBOL_GRAVITY = 400;
export const SYMBOL_WIND = 0;
export const DELAY_BETWEEN_TWO_SYMBOL = 30;
export const DELAY_BETWEEN_TWO_REEL = 100;
export const FINISH_INSTANTLY_DELAY_BETWEEN_TWO_SYMBOL = 10;
export const FINISH_INSTANTLY_DELAY_BETWEEN_TWO_REEL = 10;
export const STORAGE_VERSION = '1.6';
