import SoundTrack from '../../../../game/managers/soundManager/soundTrack';
import SoundBase from '../../../../game/managers/soundManager/soundBase';
import GeneralGameplayMusicTrack from './generalGameplayMusicTrack';
import SoundEffectsTrack from './soundEffectsTrack';
import CachedSettingsManager from '../../../../cachedSettingsManager';

class BigWinTrack extends SoundTrack {
  private _bigWinMusicTypePlaying: SoundBase | undefined = undefined;
  private _bigWinMusicType!: 'big' | 'mega' | 'olympus';
  private _generalGameplayMusicTrack?: GeneralGameplayMusicTrack;
  private _soundEffectsTrack?: SoundEffectsTrack;

  mount(generalGameplayMusicTrack: GeneralGameplayMusicTrack, soundEffectsTrack: SoundEffectsTrack) {
    this.registerMusic('musBigWin');
    this.registerMusic('musBigWinEnd');
    this.registerMusic('musMegaWin');
    this.registerMusic('musMegaWinEnd');
    this.registerMusic('musOlympusWin');
    this.registerMusic('musOlympusWinEnd');

    this._generalGameplayMusicTrack = generalGameplayMusicTrack;
    this._soundEffectsTrack = soundEffectsTrack;

    CachedSettingsManager.addListener('soundControlState', (value) => {
      const { musicEnabled, gameSoundEnabled } = value as unknown as {
        musicEnabled: boolean;
        gameSoundEnabled: boolean;
      };

      this.volume = musicEnabled && gameSoundEnabled ? 1 : 0;
    });

    const { musicEnabled, gameSoundEnabled } = CachedSettingsManager.get(
      'soundControlState'
    ) as unknown as {
      musicEnabled: boolean;
      gameSoundEnabled: boolean;
    };

    this.volume = musicEnabled && gameSoundEnabled ? 1 : 0;

    this._isMounted = true;
  }

  private _getBigWinMusic(type: 'big' | 'mega' | 'olympus') {
    return this.getSound(
      `mus${type.substring(0, 1).toUpperCase() + type.substring(1)}Win`
    ) as SoundBase;
  }

  private _getBigWinMusicEnd(type: 'big' | 'mega' | 'olympus') {
    return this.getSound(
      `mus${type.substring(0, 1).toUpperCase() + type.substring(1)}WinEnd`
    ) as SoundBase;
  }

  async playBigWin(type: 'big' | 'mega' | 'olympus', onProgress?: (progress: number) => void) {
    if (this._bigWinMusicTypePlaying) this._bigWinMusicTypePlaying.stop();

    const bigWinMusic = this._getBigWinMusic(type);

    this._bigWinMusicTypePlaying = bigWinMusic;
    this._bigWinMusicType = type;

    this._generalGameplayMusicTrack?.duckForSound(bigWinMusic);
    this._soundEffectsTrack?.duckForSound(bigWinMusic);

    bigWinMusic.play({
      onProgress: onProgress,
    });

    const { musicEnabled, gameSoundEnabled } = CachedSettingsManager.get(
      'soundControlState'
    ) as unknown as {
      musicEnabled: boolean;
      gameSoundEnabled: boolean;
    };

    this.volume = musicEnabled && gameSoundEnabled ? 1 : 0;
    return bigWinMusic;
  }

  transitionToBigWinEnd() {
    if (!this._bigWinMusicTypePlaying || !this._bigWinMusicType) return;

    const type = this._bigWinMusicType;
    const bigWinMusic = this._getBigWinMusic(type);
    const bigWinMusicEnd = this._getBigWinMusicEnd(type);

    this._generalGameplayMusicTrack?.duckForSound(bigWinMusicEnd);
    this._soundEffectsTrack?.duckForSound(bigWinMusicEnd);

    this.crossFade([bigWinMusic], bigWinMusicEnd, { stopAndRestoreVolume: true, duration: 500 });
    bigWinMusicEnd.play();

    const { musicEnabled, gameSoundEnabled } = CachedSettingsManager.get(
      'soundControlState'
    ) as unknown as {
      musicEnabled: boolean;
      gameSoundEnabled: boolean;
    };

    this.volume = musicEnabled && gameSoundEnabled ? 1 : 0;
    return bigWinMusicEnd;
  }

  getBigWinEndDuration(winType: 'big' | 'mega' | 'olympus') {
    return this._getBigWinMusicEnd(winType).duration;
  }

  getBigWinDuration(winType: 'big' | 'mega' | 'olympus') {
    return this._getBigWinMusic(winType).duration;
  }
}

export default BigWinTrack;
