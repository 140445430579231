import { Container, ObservablePoint, Sprite } from 'pixi.js';
import { vec2 } from 'gl-matrix';

// Do not ask me why I needed to write this function.  I have no idea why pixi Container->toGlobal() returns incorrect
//   values!
// Also this function is barebones and does not yet account for rotation, anchors, etc.  Only scale and position offsets
const getLocalCoordsFor = (
  element: Container,
  target?: Container,
  calculateWithAnchor?: boolean
) => {
  let pos = vec2.fromValues(element.position.x, element.position.y);
  let scale = vec2.fromValues(1, 1);

  let walker: Container = element;
  while (walker !== target && walker.parent) {
    if (target && !walker.parent) throw new Error('Target not found in parent chain');

    pos[0] = pos[0] * walker.parent.scale.x + walker.parent.position.x;
    pos[1] = pos[1] * walker.parent.scale.y + walker.parent.position.y;
    scale[0] *= walker.parent.scale.x;
    scale[1] *= walker.parent.scale.y;
    if (calculateWithAnchor && 'anchor' in walker && walker.anchor instanceof ObservablePoint) {
      pos[0] += walker.anchor.x * walker.width;
      pos[1] += walker.anchor.y * walker.height;
    }
    walker = walker.parent;
  }

  return { pos, scale };
};

export { getLocalCoordsFor };
