export const STANDARD_FPS = 20;
export const SHOW_BORDERS = false;
export const DESTROY_Y_LIMIT = 970;
export const SYMBOL_TYPES = 4;
export const SYMBOLS_PER_REEL = 5;
export const IS_MULTIPLIER_SYMBOL_TYPE = (symbolIndex: number) => symbolIndex > 100000;
export const ANTEBET_MULTIPLIER = 1.25;

export const REELS_COUNT = 6;
export const REELS_MARGIN_LEFT_DESKTOP = 100;
export const REELS_MARGIN_LEFT_MOBILE = 40;

export const REELS_MARGIN_TOP_DESKTOP = 100;
export const REELS_MARGIN_TOP_MOBILE = 0;

export const SYMBOL_HEIGHT = 165;
export const SYMBOL_WIDTH = 165;

export const SCATTER_HEIGHT = 217;
export const SCATTER_WIDTH = 217;

export const LONG_DIMENSION = 1920;
export const SHORT_DIMENSION = 1100;

export const SYMBOL_GAP = 0;
export const SYMBOL_Y_OFFSET = 80;
export const SYMBOL_X_OFFSET = 80;

export const REELS_MASK_OFFSET = 20;
export const PORTRAIT_LOGO_OFFSET_Y = -50;
export const PORTRAIT_LOGO_OFFSET_X = 10;

export const LANDSCAPE_LOGO_OFFSET_Y = 860;
export const LANDSCAPE_LOGO_OFFSET_X = -50;

export const REELS_CONTAINER_OFFSET_Y = -50;
export const REELS_CONTAINER_OFFSET_X = 0;

export const REELS_ROOF_OFFSET_Y = -196;
export const REELS_ROOF_OFFSET_X = 1200;

export const REELS_BACKGROUND_OFFSET_Y = 60;
export const REELS_BACKGROUND_OFFSET_X = 0;

export const SYMBOL_SHAKE_MAGNITUDE = 5;
export const SYMBOL_SHAKE_DURATION = 200;
export const DELAY_AFTER_SELECTION = 1200;

export const SYMBOLS_CONTAINER_OFFSET_X = 1388;
export const SYMBOLS_CONTAINER_OFFSET_Y = 0;

export const SEPARATORS_CONTAINER_OFFSET_X = 1374;
export const SEPARATORS_CONTAINER_OFFSET_Y = 85;

export const HIGH_SYMBOL_SCALE = 0.17;
export const MULTIPLIER_SYMBOL_SCALE = 0.17;
export const LOW_SYMBOL_SCALE = 0.7;
export const SCATTER_SYMBOL_SCALE = 0.18;

export const JACKPOT_META_X_OFFSET = 800;
export const JACKPOT_META_Y_OFFSET = 800;

export const REELS_MANAGER_RIGHT_END = -1381;
