import { Spine } from '@pixi/spine-pixi';
import { Game } from '../../../games/game';
import { Container } from 'pixi.js';
import delay from 'delay';
import { simpleAnimatePropertiesTo } from '../animationManager';
import { IGameEventDetails } from '../../../games/gameEvent';
import { IGameOutcome } from '../../../games/dede/service/types';
import Popup from '../../../games/dede/models/popup';
import { getLocale } from '../../../../localization';
import { splitSentence } from '../../../utils/text';
import { formatAsCurrency } from '../currencyManager';

export class MaxWinManager {
  constructor(private game: Game) {}
  processMaxWinOutcome = (outcome?: IGameOutcome) => {
    if (!outcome?.maxWinReached) return false;
    this.game.handleMaxWinReached();

    const showMaxWinPopup = () => {
      const maxWinPopup = new Popup(
        [
          getLocale('slots.ui.common.congratulations'),
          ...splitSentence(getLocale('slots.ui.dede.youWonMaxAmount'), 20),
          formatAsCurrency(this.game.maxWinAmount),
        ].map((label) => ({ label })),

        {
          onClose: () => {
            this.game.coinFountainManager.stop();
            this.game.betButtonsDisabler.enableAll();
            this.game.freeSpinManager.transitionBack();
          },
          zIndex: 6,
        }
      );
      maxWinPopup.mount();
      this.game.coinFountainManager.animate({ isRain: true, zIndex: 7, count: 1 });
    };
    if (this.game.bigWinManager.isActive)
      this.game.onBigWinAnimationCompleted.addEventListenerOnce(showMaxWinPopup);
    else showMaxWinPopup();
    return true;
  };
}
