import { Sprite, Texture } from 'pixi.js';
import { IFreeSpinStatus } from '../types';
import { Spine } from '@pixi/spine-pixi';
import { Game } from '../game';

export class BackgroundManager {
  spine!: Spine;
  sprite!: Sprite;
  freeSpinStatus?: IFreeSpinStatus;
  constructor(private game: Game) {
    this.game.onFreeSpinStarted.addEventListener(() => {
      this.mountFreeSpinBackground();
    });
    this.game.onFreeSpinEnded.addEventListener(() => {
      this.mountMainBackground();
    });
  }

  unMountSpine() {
    if (this.sprite) {
      this.game.app.stage.removeChild(this.sprite);
    }
    if (this.spine) {
      this.game.app.stage.removeChild(this.spine);
    }
  }

  mountSpine(isFreeSpin = false) {
    this.sprite = new Sprite(Texture.from('backgroundImage'));
    this.sprite.anchor.set(0.5);
    this.spine = Spine.from({ skeleton: 'backgroundData', atlas: 'backgroundAtlas' });
    if (isFreeSpin) this.mountFreeSpinBackground();
    else this.mountMainBackground();

    this.game.app.stage.addChild(this.sprite);
    this.game.app.stage.addChild(this.spine);
    this.handleResize();
  }

  handleResize = () => {
    this.sprite.x = this.game.width / 2;
    this.sprite.y = this.game.height / 2 - 110;
    this.spine.x = this.game.width / 2;
    this.spine.y = this.game.height / 2;

    this.sprite.scale.set(1);
    this.spine.scale.set(0.5);
  };

  async init() {
    this.mountSpine();
    this.game.onResize.addEventListener(this.handleResize);
    this.handleResize();
  }

  processFreeSpinBackgroundChange(isFreeSpin = false) {
    if (!this.spine) return;

    this.unMountSpine();
    this.mountSpine(isFreeSpin);
  }

  mountFreeSpinBackground() {
    this.sprite.texture = Texture.from('FSBackgroundImage');
    this.spine.state.setAnimation(0, 'FS_backround', true);
  }

  mountMainBackground() {
    this.sprite.texture = Texture.from('backgroundImage');
    this.spine.state.setAnimation(0, 'main_backround', true);
  }
}
