import { Assets } from 'pixi.js';
import { sound } from '@pixi/sound';

import { registerLogCategory } from '../../debug/privateLogger';

const log = registerLogCategory('AssetLoader');

type TAssetItem = {
  alias: string;
  src: string;
  loadLazy?: boolean;
  preload?: boolean;
};

const getIsEdgeOnAndroid = () => {
  const ua = navigator.userAgent;
  return /EdgA/.test(ua) || (/Edge/.test(ua) && /Android/.test(ua));
};

// Asset loader loads a list of assets that it is constructed with (once load is actually triggered).
class AssetLoader {
  initialItemsLoaded = false;
  lazyItemsLoaded = false;
  preloadItemsLoaded = false;
  items: TAssetItem[] = [];

  constructor(items: TAssetItem[]) {
    log(1)('Constructor', { items });

    const isEdgeOnAndroid = getIsEdgeOnAndroid();

    this.items = items.map((item) => {
      const ret = item;
      if (isEdgeOnAndroid && ret.src.match(/.*\.(mp3|m4a|ogg)$/))
        ret.src = ret.src.replace(/(.*)\.(mp3|m4a|ogg)$/, '$1.mp3');
      return ret;
    });

    if (!Assets.loader.parsers?.find(({ name }) => name === 'soundParser')) {
      log(3)('soundParser not found, adding it');
      Assets.loader.parsers.push({
        name: 'soundParser',
        test: (url) => !!url.match(/^.*\.(mp3|m4a|ogg)$/),
        parse: ({ name, src }) =>
          new Promise((resolve) => {
            const soundInstance = sound.add(name, src);
            resolve(soundInstance);
          }),
      });
    }
  }

  private loadItems = (
    items: TAssetItem[],
    identifier: string,
    onProgress?: (progress: number) => void,
    onLoaded?: () => void,
  ) => {
    items.forEach(({ alias, src }) => {
      Assets.add({ alias, src });
    });

    log(3)(`(${identifier}) Start load of assets`, { items });
    return Assets.load(
      items.map(({ alias }) => alias),
      (progress) => {
        const _progress = Math.min(0.999999, progress);
        log(4)(`(${identifier}) Assets load in progress: `, _progress);
        onProgress?.(_progress);
      },
    ).then(() => {
      log(2)(`(${identifier}) Assets loaded`);
      onLoaded?.();
      onProgress?.(1);
    });
  };

  // Returns a promise that will resolve when non-lazy assets are loaded and READY for use.
  // You can give it a progress callback for tracking progress.
  private loadInitialAssets = (onProgress?: (progress: number) => void) => {
    // Prep a list of non-lazy assets from the constructer's assets list and inform PIXI about them
    const initialItems = this.items.filter(({ loadLazy, preload }) => !loadLazy && !preload);
    log(3)('Start load of initial assets');

    return this.loadItems(initialItems, 'initialAssets', onProgress, () => {
      this.initialItemsLoaded = true;
    });
  };

  private loadLazyAssets = (onProgress?: (progress: number) => void) => {
    // Prep a list of lazy assets from the constructer's assets list and inform PIXI about them
    const lazyItems = this.items.filter(({ loadLazy }) => loadLazy);
    log(3)('Start load of lazy assets');
    return this.loadItems(lazyItems, 'lazyAssets', onProgress, () => {
      this.lazyItemsLoaded = true;
    });
  };

  private loadPreloadAssets = (onProgress?: (progress: number) => void) => {
    const preloadItems = this.items.filter(({ preload }) => preload);
    log(3)('Start load of preload assets');
    return this.loadItems(preloadItems, 'preloadAssets', onProgress, () => {
      this.preloadItemsLoaded = true;
    });
  };

  // Will start loading all assets and return a promise that resolves upon completion of all assets
  load = async (
    onProgress: (progress: number) => void,
    onProgressLazy: (progress: number) => void,
    onProgressPreload: (progress: number) => void,
  ) => {
    log(2)('Initiating asset load', { onProgress, onProgressLazy });
    await this.loadPreloadAssets(onProgressPreload);
    await this.loadInitialAssets(onProgress);
    return this.loadLazyAssets(onProgressLazy);
  };
}

export default AssetLoader;

export { type TAssetItem };
