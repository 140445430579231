import { Container, Sprite, Text, Texture } from 'pixi.js';
import { IPosition } from '../../../types';

const INACTIVITY_TIMEOUT = 30000;
class Button {
  pressed = false;
  spacePressed = false;
  background: Sprite;
  maskSprite?: Sprite;
  content!: Sprite;
  container: Sprite;
  rotateOnPress = false;
  private _text!: Text;
  private _text2!: Text;
  private _disabled = false;
  private _longPressActive = false;
  private _rotateInterval: NodeJS.Timeout | null = null;
  private _lastUserInteraction = Date.now();

  constructor(
    parent: Container,
    private buttonOptions: {
      backgroundTexture: string;
      disabledBackgroundTexture?: string;
      texture?: string;
      text?: string;
      text2?: string;
      fontSize?: number;
      fontSize2?: number;
      onClick: () => void;
      contentPosition?: IPosition;
      content2Position?: IPosition;
      backgroundPosition: IPosition;
      disableShadow?: boolean;
      text1Alignment?: 'left' | 'center';
      text2Alignment?: 'left' | 'center';
      listenSpace?: boolean;
      longPressBehavior?: 'none' | 'repeat';
      rotateOnPress?: boolean;
      isAnimatedWhenInactive?: boolean;
    }
  ) {
    this.container = new Sprite();
    this.background = new Sprite(Texture.from(buttonOptions.backgroundTexture));
    this.background.width = buttonOptions.backgroundPosition.width || 0;
    this.background.height = buttonOptions.backgroundPosition.height || 0;

    this.container.x = buttonOptions.backgroundPosition.x;
    this.container.y = buttonOptions.backgroundPosition.y;
    this.background.anchor.set(0.5);
    this.rotateOnPress = !!buttonOptions.rotateOnPress;

    this.container.addChild(this.background);
    if (buttonOptions.texture) {
      this.content = new Sprite(Texture.from(buttonOptions.texture));
      if (buttonOptions.contentPosition?.width)
        this.content.width = buttonOptions.contentPosition.width;
      if (buttonOptions.contentPosition?.height)
        this.content.height = buttonOptions.contentPosition.height;
      if (buttonOptions.contentPosition?.x) this.content.x = buttonOptions.contentPosition.x;
      if (buttonOptions.contentPosition?.y) this.content.y = buttonOptions.contentPosition.y;
      this.content.anchor.set(0.5);
      this.background.addChild(this.content);
    }

    if (buttonOptions.disabledBackgroundTexture) {
      this.maskSprite = new Sprite(Texture.from(buttonOptions.disabledBackgroundTexture));
      this.maskSprite.width = buttonOptions.backgroundPosition.width || 0;
      this.maskSprite.height = buttonOptions.backgroundPosition.height || 0;
      this.maskSprite.anchor.set(0.5);
      this.maskSprite.alpha = 0.7;
      this.maskSprite.visible = false;
      this.container.addChild(this.maskSprite);
    }

    if (buttonOptions.text) {
      const text = new Text();
      this._text = text;
      text.style = {
        fontFamily: 'ManchoBold',
        fontSize: buttonOptions.fontSize || 24,
        fill: 0xffffff,
        align: buttonOptions.text1Alignment || 'center',
      };
      text.text = buttonOptions.text;
      if (buttonOptions.text1Alignment !== 'left') text.anchor.set(0.5);
      else text.anchor.set(0, 0.5);
      text.x = buttonOptions.contentPosition?.x || 0;
      text.y = buttonOptions.contentPosition?.y || 0;
      this.background.addChild(text);
    }
    if (buttonOptions.text2) {
      const text = new Text();
      this._text2 = text;
      text.style = {
        fontFamily: 'ManchoBold',
        fontSize: buttonOptions.fontSize2 || 24,
        fill: 0xffffff,
        align: buttonOptions.text2Alignment || 'center',
      };
      text.text = buttonOptions.text2;
      if (buttonOptions.text2Alignment !== 'left') text.anchor.set(0.5);
      else text.anchor.set(0, 0.5);

      text.x = buttonOptions.content2Position?.x || 0;
      text.y = buttonOptions.content2Position?.y || 0;
      this.background.addChild(text);
    }
    if (!buttonOptions.disableShadow) {
      // const shadow = new DropShadowFilter({
      //   color: 0xffffff, // White shadow
      //   blur: 12, // Blur effect
      //   alpha: 0.7, // Transparency
      //   quality: 12, // Quality of the shadow
      // });
      // this.background.filters = [shadow];
    }

    this.container.interactive = true;
    this.container.cursor = 'pointer'; // Set cursor to pointer on hover
    this.container.anchor.set(0.5);
    parent.addChild(this.container);

    this.container.on('pointerdown', () => this.handleDown(false));
    this.container.on('pointerup', () => this.handleUp(false));
    if (buttonOptions.listenSpace) {
      window.addEventListener('keydown', (e) => {
        if (e.key === ' ') {
          if (!this.spacePressed) {
            this.handleDown(true);
          }
          this.buttonOptions.onClick();
        }
      });
      window.addEventListener('keyup', (e) => {
        if (e.key === ' ') this.handleUp(true);
      });
    }

    this.container.on('pointerout', () => this.handleUp(false));
    window.game?.onActivity.addEventListener(() => {
      this._lastUserInteraction = Date.now();
    });
    this.mountAnimateInactivityRoutine();
  }

  set text(text: string) {
    this._text!.text = text;
  }

  get text() {
    return this._text.text;
  }

  set text2(text: string) {
    this._text2!.text = text;
  }

  get text2() {
    return this._text2.text;
  }

  set disabled(disabled: boolean) {
    this._disabled = disabled;
    this.container.interactive = !disabled;
    this.container.cursor = disabled ? 'not-allowed' : 'pointer';
    if (this.buttonOptions.disabledBackgroundTexture && this.maskSprite) {
      if (disabled) this.maskSprite.visible = true;
      else this.maskSprite.visible = false;
    }

    if (this.buttonOptions.contentPosition?.hideWhenDisabled && this.content) {
      this.content.visible = !disabled;
    }
  }

  get disabled() {
    return this._disabled;
  }

  set visible(visible: boolean) {
    this.container.visible = visible;
  }

  get visible() {
    return this.container.visible;
  }

  destroy() {
    this.container.destroy();
  }

  doRotationEffect = async (rotateBack = false, instantly = false) => {
    if (!this.content) return;
    clearInterval(this._rotateInterval!);
    let rotation = rotateBack ? 1 : 0;
    if (instantly) {
      this.content.rotation = rotateBack ? 0 : 1;
      return;
    }
    return new Promise<void>((resolve) => {
      this._rotateInterval = setInterval(() => {
        if (rotateBack) rotation -= 0.1;
        else rotation += 0.1;
        if (rotation < 0) {
          rotation = 0;
          clearInterval(this._rotateInterval!);
          resolve();
        }
        else if (rotation > 1) {
          rotation = 1;
          clearInterval(this._rotateInterval!);
          resolve();
        }
        this.content.rotation = rotation;
      }, 20);
    });
  };

  changeTexture(texture: string) {
    this.content.texture = Texture.from(texture);
    this.doRotationEffect(true, true);
  }

  doClickEffect = () => {
    this.container.scale.set(this.container.scale.x * 0.9);
    this.container.tint = 0xaaaaaa;
    setTimeout(() => {
      this.container.scale.set(this.container.scale.x / 0.9);
      this.container.tint = 0xffffff;
    }, 100);
  };

  handleDown = (isSpace: boolean) => {
    window.game!.onClick.triggerEvent();
    this._lastUserInteraction = Date.now();
    this.container.scale.set(this.container.scale.x * 0.9);
    this.container.tint = 0xaaaaaa;
    // rotate the button
    if (this.rotateOnPress) this.doRotationEffect();

    if (isSpace) this.spacePressed = true;
    else {
      this.pressed = true;
      if (this.buttonOptions.longPressBehavior === 'repeat') {
        setTimeout(() => {
          if (this.pressed) {
            this._longPressActive = true;
            const interval = setInterval(() => {
              if (this._longPressActive) this.buttonOptions.onClick();
              else clearInterval(interval);
            }, 100);
          }
        }, 1000);
      }
    }
  };

  handleUp = (isSpace: boolean) => {
    this._lastUserInteraction = Date.now();
    const pressed = isSpace ? this.spacePressed : this.pressed;
    if (!pressed) return;
    if (this.rotateOnPress) {
      this.doRotationEffect(true);
      if (this.content) this.content.rotation = 0;
    }

    this.container.scale.set(this.container.scale.x / 0.9);
    this.container.tint = 0xffffff;
    if (isSpace) {
      this.spacePressed = false;
      this.buttonOptions.onClick();
    }
    else {
      this.pressed = false;
      if (this._longPressActive) this._longPressActive = false;
      else this.buttonOptions.onClick();
    }
  };

  mountAnimateInactivityRoutine() {
    if (this.buttonOptions.isAnimatedWhenInactive) {
      const interval = setInterval(async () => {
        if (Date.now() - this._lastUserInteraction > INACTIVITY_TIMEOUT) {
          this._lastUserInteraction = Date.now();
          await this.doRotationEffect();
          await this.doRotationEffect(true);
        }
      }, 1000);
    }
  }
}

export default Button;
