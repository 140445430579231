import { Graphics, Sprite, Texture, Text, Container, BitmapText } from 'pixi.js';
import { Dede } from '.';
import { formatAsCurrency } from '../../game/managers/currencyManager';
import GameEvent, { IEventDetails } from '../gameEvent';
import { StakeChangeListener } from '../types';
import { performPulseAnimation } from '../../game/managers/animationManager/animations';
import { IGameOutcome, IGameSpinResponse, IJackpotResponse } from './service/types';
import Popup from './models/popup';
import { getLocale } from '../../../localization';
import { splitSentence } from '../../utils/text';
import accamaxDebug from '../../debug';
import { Bone, Spine } from '@pixi/spine-pixi';
import delay from 'delay';
import { fontSafeString } from './resources/fonts/fonts';
import { TransitionManager } from '../../game/managers/transitionManager';
import { ANTEBET_MULTIPLIER } from './resources/constants';
import CachedSettingsManager from '../../cachedSettingsManager';

const X_OFFSET = 1355;
const Y_OFFSET = -10;
const COLUMN_SIZE = 266.666;
const JACKPOT_BANNER_TEXT_X_OFFSET = 2;
const JACKPOT_BANNER_TEXT_Y_OFFSET = 45;
const JACKPOT_BANNER_FONT_SIZE = 34;
const JACKPOT_BANNER_TEXT_LIMIT = 220;
const JACKPOT_MINOR_STAKE_MULTIPLIER = 25;
const JACKPOT_MINI_STAKE_MULTIPLIER = 10;
const WIN_AMOUNT_TEXT_Y_OFFSET = 150;
const WIN_AMOUNT_TEXT_X_OFFSET = 255;

// jackpot enums
export enum JackpotTier {
  mini = 1,
  minor = 2,
  major = 3,
  grand = 4,
}

class ScaleTrackedText extends Text {
  public lastDigitCount = 1;
}

export class JackpotManager {
  container = new Graphics();
  jackpotGameContainer!: Graphics;
  jackpotGameBackground!: Sprite;
  vaultSpine!: Spine;

  jackpotResponse: IJackpotResponse | undefined;
  clickIndex = 0;
  disableClick = false;
  coinScale = 0.5;

  private _grandText!: ScaleTrackedText;
  private _grandSprite!: Sprite;
  private _grandJackpotValue!: number;
  private _majorText!: ScaleTrackedText;
  private _majorSprite!: Sprite;
  private _majorJackpotValue!: number;
  private _minorText!: ScaleTrackedText;
  private _minorSprite!: Sprite;
  private _miniText!: ScaleTrackedText;
  private _miniSprite!: Sprite;
  private _stakeValue!: number;
  private _transitionManager: TransitionManager;
  private _continueText!: Text;
  private _amountText!: BitmapText;
  private _anchorBone: Bone | null = null;
  private _animationPromises: {
    tier: JackpotTier;
    promise: Promise<void>;
  }[] = [];

  private _animationQueue: {
    tier: JackpotTier;
    value: number;
  }[] = [];

  private _devJackpotTrigger: { amount: number; tier: number } | null = null;

  jackpotPopup!: Popup;

  constructor(private game: Dede) {
    accamaxDebug.debug.triggerJackpot = (tier = 'mini') => {
      window.testData = { jackpotTier: tier };
      this.game.runReels(false);
    };
    this._transitionManager = new TransitionManager(this.game);

    accamaxDebug.debug.triggerJackpot = (tier = 1, amount = 100) => {
      this._devJackpotTrigger = { amount, tier };
    };
  }

  handleStakeChange = () => {
    const stake = this.game.stake * (this.game.anteBetActive ? ANTEBET_MULTIPLIER : 1);
    this._handleStakeChanged(stake);
  };

  async mount(stake: number) {
    this._stakeValue = stake;

    this._createJackpotVariant('jpGrand', 0x8e2014, this._grandJackpotValue, 0, JackpotTier.grand);

    this._createJackpotVariant('jpMajor', 0xa77300, this._majorJackpotValue, 1, JackpotTier.major);

    this._createJackpotVariant(
      'jpMinor',
      0x3d7825,
      stake * JACKPOT_MINOR_STAKE_MULTIPLIER,
      2,
      JackpotTier.minor
    );

    this._createJackpotVariant(
      'jpMini',
      0x2e487b,
      stake * JACKPOT_MINI_STAKE_MULTIPLIER,
      3,
      JackpotTier.mini
    );

    this.game.reelsManager.container.addChild(this.container);

    this.container.x = X_OFFSET;
    this.container.y = Y_OFFSET;
    this.container.zIndex = 5;

    this.game.onStakeChange.addEventListener(this.handleStakeChange);
    this.game.onAnteBetChange.addEventListener(this.handleStakeChange);
  }

  private _getLabelSpriteAndText = (tier: JackpotTier) => {
    switch (tier) {
      case JackpotTier.mini:
        return {
          sprite: this._miniSprite,
          text: this._miniText,
        };
      case JackpotTier.minor:
        return {
          sprite: this._minorSprite,
          text: this._minorText,
        };
      case JackpotTier.major:
        return {
          sprite: this._majorSprite,
          text: this._majorText,
        };
      case JackpotTier.grand:
        return {
          sprite: this._grandSprite,
          text: this._grandText,
        };
    }
  };

  private _checkAnimationQueue = (tier: JackpotTier) => {
    const animationData = this._animationQueue.findLast((q) => q.tier === tier);
    if (animationData) {
      this._updateJackpotVariantValue(animationData.value, tier);
      this._animationQueue = this._animationQueue.filter((q) => q.tier !== tier);
    }
  };

  private _updateJackpotVariantValue(value: number, tier: JackpotTier, performAnimation = true) {
    const { sprite, text } = this._getLabelSpriteAndText(tier);
    if (!value || !text || !sprite) return;

    if (performAnimation) {
      const promises = this._animationPromises.filter((p) => p.tier === tier);
      if (promises.length) {
        this._animationQueue.push({ tier, value });
        return;
      }
    }

    const origScale = text.scale.x;
    let targetScale = origScale;

    const textUpdateCallback = () => {
      const digitCount = Math.floor(Math.log10(value));
      if (digitCount !== text.lastDigitCount) {
        text.text = formatAsCurrency(Math.pow(10, digitCount) * 4);
        const lastScale = text.scale.x;
        text.scale.set(1);

        if (text.width > JACKPOT_BANNER_TEXT_LIMIT) {
          const overlap = text.width - JACKPOT_BANNER_TEXT_LIMIT;
          targetScale = 1 - overlap / text.width;
        }
        text.scale.set(lastScale);
        text.lastDigitCount = digitCount;
      }

      text.text = formatAsCurrency(value);
      return { scale: targetScale };
    };

    if (!performAnimation) {
      textUpdateCallback();
      return;
    }

    const promise = performPulseAnimation(text, {
      midChangeCallback: () => {
        textUpdateCallback();
      },
      maxBrightness: 2,
      maxGlowStrength: 8,
      maxGlowDistance: 12,
      maxGrowScale: 1.1,
      durationGrow: 150,
      durationShrink: 150,
    });

    const animationPromise = { tier, promise };
    this._animationPromises.push(animationPromise);
    promise.then(() => {
      this._animationPromises = this._animationPromises.filter((p) => p !== animationPromise);
      this._checkAnimationQueue(tier);
    });
    return promise;
  }

  private _createJackpotVariant(
    textureName: string,
    color: number,
    value: number,
    position: number,
    tier: JackpotTier
  ) {
    const sprite = new Sprite(Texture.from(textureName));
    this.container.addChild(sprite);
    sprite.x = COLUMN_SIZE * position;

    const valueText = new ScaleTrackedText();
    sprite.addChild(valueText);
    valueText.anchor.set(0.5, 0.5);
    valueText.x = sprite.width / 2 + JACKPOT_BANNER_TEXT_X_OFFSET;
    valueText.y = JACKPOT_BANNER_TEXT_Y_OFFSET;
    valueText.lastDigitCount = 0;
    valueText.style = {
      fill: color,
      fontSize: JACKPOT_BANNER_FONT_SIZE,
      fontFamily: 'ManchoBold',
    };

    if (tier === JackpotTier.grand) {
      this._grandText = valueText;
      this._grandSprite = sprite;
    } else if (tier === JackpotTier.major) {
      this._majorText = valueText;
      this._majorSprite = sprite;
    } else if (tier === JackpotTier.minor) {
      this._minorText = valueText;
      this._minorSprite = sprite;
    } else if (tier === JackpotTier.mini) {
      this._miniText = valueText;
      this._miniSprite = sprite;
    }

    if (value) this._updateJackpotVariantValue(value, tier, false);
  }

  public updateJackpotValues({
    majorJackpotValue,
    grandJackpotValue,
  }: {
    majorJackpotValue: number;
    grandJackpotValue: number;
  }) {
    if (this._grandJackpotValue !== grandJackpotValue) {
      this._grandJackpotValue = grandJackpotValue;
      this._updateJackpotVariantValue(grandJackpotValue, JackpotTier.grand);
    }

    if (this._majorJackpotValue !== majorJackpotValue) {
      this._majorJackpotValue = majorJackpotValue;
      this._updateJackpotVariantValue(majorJackpotValue, JackpotTier.major);
    }
  }

  private async _handleStakeChanged(newStake: number) {
    this._stakeValue = newStake;

    this._updateJackpotVariantValue(
      this._stakeValue * JACKPOT_MINOR_STAKE_MULTIPLIER,
      JackpotTier.minor
    );
    this._updateJackpotVariantValue(
      this._stakeValue * JACKPOT_MINI_STAKE_MULTIPLIER,
      JackpotTier.mini
    );
  }

  handleResize = () => {
    if (!this.vaultSpine) return;
    this.coinScale = this.game.reelsManager.scale * 0.5;
    this.vaultSpine.scale.set(this.game.reelsManager.scale * 0.4);
    this.vaultSpine.x = this.game.width / 2;
    this.vaultSpine.y = this.game.height / 2;

    if (this._continueText && !this._continueText.destroyed) {
      this._continueText.scale.set(this.game.reelsManager.scale);
      this._continueText.x = this.game.width / 2;
      this._continueText.y = this.game.height - 200;
    }
  };

  handleTicker = () => {
    const bone = this._anchorBone;
    if (bone && this._amountText && this.vaultSpine) {
      const worldX = this.vaultSpine.x + bone.worldX;
      const worldY = this.vaultSpine.y + bone.worldY;
      const boneScale = Math.abs(bone.scaleX);

      this._amountText.x = worldX + WIN_AMOUNT_TEXT_X_OFFSET * this.vaultSpine.scale.x;
      this._amountText.y = worldY + 205 + 145 * boneScale;
      this._amountText.scale.set(boneScale);
      this._amountText.scale.set((boneScale * 700) / (this._amountText.width / boneScale));
    }
  };

  async animateVault() {
    let resolve = () => {};
    let promise = new Promise<void>((res) => (resolve = res));
    const tierString = JackpotTier[this.jackpotResponse?.winningJackpotTier || 1].toLowerCase();
    const winAnimation = this.vaultSpine.state.setAnimation(0, `win_${tierString}`, false);
    const showTexts = () => {
      this._anchorBone = this.vaultSpine?.skeleton.findBone('mainW');
      if (this.jackpotResponse!.winningJackpotTier > JackpotTier.minor)
        this.game.coinFountainManager.animate({
          container: this.jackpotGameContainer,
          zIndex: 0,
          count: 2,
          randomSize: true,
          // randomSpeed: true,
        });

      const amountText = new BitmapText({
        text: fontSafeString(
          'goldenTextFontOld',
          formatAsCurrency(this.jackpotResponse?.winAmount || 0)
        ),
        style: {
          fontFamily: 'goldenTextFontOld',
          fontSize: 200,
        },
      });

      amountText.anchor.set(0.5);
      amountText.zIndex = 5000;
      this.game.app.stage.addChild(amountText);

      this.game.app.ticker.add(this.handleTicker);

      this._amountText = amountText;

      this._continueText = new Text();
      this._continueText.text = getLocale('slots.ui.dede.pressContinue').toUpperCase();
      this._continueText.anchor.set(0.5);
      this._continueText.style = {
        fill: 0xffffff,
        fontSize: 60,
        fontFamily: 'ManchoBold',
        dropShadow: true,
      };
      this._continueText.anchor.set(0.5);
      this._continueText.zIndex = 5000;
      this.game.app.stage.addChild(this._continueText);
      this.handleResize();
    };
    setTimeout(() => {
      showTexts();
    }, 5900);
    winAnimation.listener = {
      complete: () => {
        setTimeout(() => {
          this.vaultSpine.state.setAnimation(0, `idle_${tierString}`, true);
        }, 0);
        // this.vaultSpine.state.setAnimation(0, 'idle', false);
        resolve();
      },
    };
    return promise;
  }

  handleClick = async () => {
    if (this._continueText) {
      this.game.onClickOrPress.removeEventListener(this.handleClick);
      const animationTime = this._transitionManager.animateTransition(() => {
        this.game.app.ticker.remove(this.handleTicker);
        this.jackpotGameContainer.destroy();
        this._amountText.destroy();
        this._continueText.destroy();
        this.game.coinFountainManager.stop();
        this.game.soundManager.jackpotMusicTrack.stopJackpotMusic();
      }, true);
      await delay(animationTime);
      this.game.gameDisabler.enable('jackpot');
    }
  };

  async mountJackpotGame() {
    this.jackpotGameContainer = new Graphics();
    this.jackpotGameBackground = new Sprite(Texture.from('jackpotBackground'));
    this.jackpotGameBackground.scale.set(4);
    this.jackpotGameBackground.anchor.set(0.5);
    this.jackpotGameBackground.x = this.game.width / 2;
    this.jackpotGameBackground.y = this.game.height / 2;
    this.jackpotGameBackground.visible = false;
    this.jackpotGameContainer.addChild(this.jackpotGameBackground);
    this.jackpotGameContainer.x = 0;
    this.jackpotGameContainer.y = 0;
    this.jackpotGameContainer.zIndex = 100;

    this.jackpotGameContainer.interactive = true;
    this.jackpotGameContainer.on('pointerdown', this.handleClick);
    this.game.onClickOrPress.addEventListener(this.handleClick);

    this.vaultSpine = Spine.from({
      skeleton: `jackpotNewData`,
      atlas: `jackpotNewAtlas`,
    });
    this.vaultSpine.zIndex = 1;

    this._transitionManager.animateTransition(() => {
      this.game.reelsManager.stopJackpotWin();
      this.game.onResize.addEventListener(this.handleResize);
      this.game.app.stage.addChild(this.jackpotGameContainer);

      this.jackpotGameBackground.visible = true;
      this.jackpotGameContainer.addChild(this.vaultSpine);
      this.animateVault();

      this.handleResize();
    });
  }

  processSpinResponse(spinResponse: IGameSpinResponse) {
    this.jackpotResponse = spinResponse.jackpotResponse;
    this.clickIndex = 0;
    this.disableClick = false;
    const jackpotAmount = this._devJackpotTrigger ? this._devJackpotTrigger.amount : this.jackpotResponse?.winAmount;
    const jackpotTier = this._devJackpotTrigger ? this._devJackpotTrigger.tier : this.jackpotResponse?.winningJackpotTier;
    if (jackpotAmount > 0) {
      const handleFallComplete = async () => {
        this.game.gameDisabler.disable('jackpot');
        this.game.reelsManager.animateJackpotWin();
        await delay(3000);

        const jackpotSprite = new Sprite(Texture.from('jackpot'));
        jackpotSprite.anchor.set(0.5);
        this.jackpotPopup = new Popup(
          splitSentence(getLocale('slots.ui.common.congratulationsForEnteringJackpot'), 15).map(
            (el) => ({ label: el })
          ),
          {
            onClose: () => {
              this.game.soundManager.jackpotMusicTrack.playJackpotMusic(
                // @ts-ignore
                jackpotTier!
              );
              this.mountJackpotGame();
            },
            sprites: [{ sprite: jackpotSprite, yOffset: 20 }],
          }
        );
        this.game.soundManager.soundEffectsTrack?.playJackpotTrigger();
        this.jackpotPopup.mount();
        this._devJackpotTrigger = null;
        this.game.onBeforeFallComplete.removeEventListener(handleFallComplete);
      };

      this.game.onBeforeFallComplete.addEventListener(handleFallComplete);
    }
    this.updateJackpotValues({
      grandJackpotValue: spinResponse.grandJackpot.balance,
      majorJackpotValue: spinResponse.majorJackpot.balance,
    });

    const jackpotMetaSymbolCount = spinResponse.jackpotResponse.jackpotSymbols.length;
    if (jackpotMetaSymbolCount) {
      this.game.onJackpotSymbolLanded.addEventListenerOnce(() => {
        this.game.soundManager.soundEffectsTrack?.playJackpotMetaLand(
          jackpotMetaSymbolCount as 1 | 2 | 3
        );
      });
    }
  }
}
