import SoundBase from './soundBase';
import SoundEffect from './soundEffect';
import Music from './music';
import { simpleAnimationDuration } from '../animationManager';
import { lerp } from '../../math/interpolationFunctions';

class SoundTrack extends SoundBase {
  protected _sounds: Map<string, SoundBase> = new Map();
  protected _isMounted = false;
  protected _crossFadeVolumes: Map<SoundBase, number> = new Map();
  protected _crossFadeAnimationRemovers: Set<() => void> = new Set();

  constructor(trackName: string) {
    super(trackName, { preventRealSound: true });
  }

  get isPlaying() {
    return this._soundsList().some(([, sound]) => sound.isPlaying);
  }

  set volume(volume: number) {
    this._currentVolume = volume;

    if (this._isMounted) {
      this._soundsList().forEach(
        ([, sound]) => {
          sound.volume = (this.getVolume() ?? 1) * (this._crossFadeVolumes.get(sound) ?? 1);
        },
      );
    }
  }

  duckForSound(sound: SoundBase) {
    this._soundsList().filter(([, _sound]) => _sound !== sound).forEach(([, _sound]) => _sound.duckForSound(sound));
  }

  stop() {
    this._soundsList().forEach(([, sound]) => sound.stop());
    this._crossFadeVolumes.clear();
    this._crossFadeAnimationRemovers.forEach((removeAnimation) => removeAnimation());
    this._crossFadeAnimationRemovers.clear();

    this._handleStop();
  }

  logStatus() {
    this._soundsList().forEach(([key, sound]) => {
      const busyPlaying = sound.status;
      if (busyPlaying)
        console.log(`Sound ${key} is playing ${busyPlaying} instances`);
    });
  }

  registerSoundEffect(
    soundName: string,
    {
      debounceTime,
      duckingVolumeMultiplier,
      duckOutDuration,
      maxVolume,
    }: {
      debounceTime?: number;
      duckingVolumeMultiplier?: number;
      duckOutDuration?: number;
      maxVolume?: number;
    } = {},
  ) {
    this._sounds.set(
      soundName,
      new SoundEffect(
        soundName,
        {
          debounceTime,
          duckingVolumeMultiplier,
          duckOutDuration,
          maxVolume,
        },
      ),
    );
  }

  registerMusic(
    musicName: string,
    {
      loop = false,
      duckingVolumeMultiplier = 0.3,
      duckOutDuration = 300,
      maxVolume = 1,
    }: {
      loop?: boolean;
      duckingVolumeMultiplier?: number;
      duckOutDuration?: number;
      maxVolume?: number;
    } = {},
  ) {
    this._sounds.set(
      musicName,
      new Music(
        musicName,
        {
          loop,
          duckingVolumeMultiplier,
          duckOutDuration,
          maxVolume,
        },
      ),
    );
  }

  registerTrack(
    trackName: string,
    track: SoundTrack,
  ) {
    this._sounds.set(
      trackName,
      track,
    );
  }

  getSound(soundName: string) {
    if (!this._isMounted)
      throw new Error(`SoundTrack->getSound(${soundName}) is not mounted`);

    return this._sounds.get(soundName);
  }

  private _soundsList() {
    return Array.from(this._sounds.entries());
  }

  async crossFade(
    currentSongs: SoundBase[],
    newSound: SoundBase,
    {
      duration = 500,
      stopAndRestoreVolume = false,
    }: {
      duration?: number;
      stopAndRestoreVolume?: boolean;
    } = {},
  ) {
    const song2TargetVolume = newSound.getUnadjustedVolume();
    const originalSongVolumes = new Map(currentSongs.map((song) => [song, song.getUnadjustedVolume()]));
    newSound.volume = 0;

    currentSongs.forEach((song) => {
      this._crossFadeVolumes.set(song, originalSongVolumes.get(song) ?? 1);
    });
    this._crossFadeVolumes.set(newSound, 0);

    const { promise, removeAnimation } = simpleAnimationDuration(
      duration,
      ({ progress }) => {
        currentSongs.forEach((song) => {
          this._crossFadeVolumes.set(song, lerp(originalSongVolumes.get(song) ?? 1, 0, progress));
        });
        this._crossFadeVolumes.set(newSound, lerp(0, song2TargetVolume, progress));
        this.volume = this.getVolume();
      },
    );

    this._crossFadeAnimationRemovers.add(removeAnimation);

    await promise;

    currentSongs.forEach((song) => {
      this._crossFadeVolumes.delete(song);
    });
    this._crossFadeVolumes.delete(newSound);
    this._crossFadeAnimationRemovers.delete(removeAnimation);

    if (stopAndRestoreVolume) {
      currentSongs.forEach((song) => {
        song.stop();
        song.volume = originalSongVolumes.get(song) ?? 1;
      });
    }
  }
}

export default SoundTrack;
