import SoundTrack from '../../../../game/managers/soundManager/soundTrack';
import GeneralGameplayMusicTrack from './generalGameplayMusicTrack';
import SoundEffectsTrack from './soundEffectsTrack';
import BigWinTrack from './bigWinTrack';
import JackpotMusicTrack from './jackpotMusicTrack';
import CachedSettingsManager from '../../../../cachedSettingsManager';
import { sound } from '@pixi/sound';
import { Ticker } from 'pixi.js';
import { JackpotTier } from '../../JackpotManager';

type TSoundControlState = {
  soundEffectsEnabled: boolean;
  musicEnabled: boolean;
  gameSoundEnabled: boolean;
};

CachedSettingsManager.registerSetting('soundControlState', {
  soundEffectsEnabled: false,
  musicEnabled: false,
  gameSoundEnabled: false,
});

class SoundManager extends SoundTrack {
  private _bigWinTrack!: BigWinTrack;
  private _generalGameplayMusicTrack!: GeneralGameplayMusicTrack;
  private _soundEffectsTrack?: SoundEffectsTrack;
  private _jackpotMusicTrack!: JackpotMusicTrack;

  mount() {
    this.volume = 1;

    this._generalGameplayMusicTrack = new GeneralGameplayMusicTrack('generalGameplayMusicTrack');
    this.registerTrack('generalGameplayMusicTrack', this._generalGameplayMusicTrack);
    this._generalGameplayMusicTrack?.mount();
    this._soundEffectsTrack = new SoundEffectsTrack('soundEffectsTrack');
    this.registerTrack('soundEffectsTrack', this._soundEffectsTrack);
    this._soundEffectsTrack?.mount();
    this._bigWinTrack = new BigWinTrack('bigWinTrack');
    this.registerTrack('bigWinTrack', this._bigWinTrack);
    this._bigWinTrack.mount(this._generalGameplayMusicTrack, this._soundEffectsTrack);
    this._jackpotMusicTrack = new JackpotMusicTrack('jackpotMusicTrack');
    this.registerTrack('jackpotMusicTrack', this._jackpotMusicTrack);
    this._jackpotMusicTrack?.mount(this.generalGameplayMusicTrack);

    // CachedSettingsManager.addListener('isSoundEnabled', (isSoundEnabled) => {
    //   this.volume = isSoundEnabled ? 1 : 0;
    // });

    this._generalGameplayMusicTrack?.startGame();

    document.addEventListener('visibilitychange', () => {
      if (document.hidden) {
        this.handleHide();
      }
      else {
        this.handleFocus();
      }
    });

    document.addEventListener('pagehide', () => {
      this.handleHide();
    });

    document.addEventListener('pageshow', () => {
      this.handleFocus();
    });

    this._isMounted = true;
  }

  get generalGameplayMusicTrack() {
    return this._generalGameplayMusicTrack;
  }

  get soundEffectsTrack() {
    return this._soundEffectsTrack;
  }

  get bigWinTrack() {
    return this._bigWinTrack;
  }

  get jackpotMusicTrack() {
    return this._jackpotMusicTrack;
  }

  resumeSounds = () => {
    if (sound.context && sound.context.audioContext.state === 'suspended') {
      sound.context.audioContext
        .resume()
        .catch((e) => {
          console.error('Error resuming Pixi Sound:', e);
        });
    }

    sound.resumeAll();
  };

  handleHide = () => {
    document.removeEventListener('touchstart', this.resumeSounds);
    document.removeEventListener('click', this.resumeSounds);
    document.addEventListener('touchstart', this.resumeSounds);
    document.addEventListener('click', this.resumeSounds);
    Ticker.shared.stop();
    sound.pauseAll();
  };

  handleFocus = () => {
    Ticker.shared.start();
    sound.resumeAll();
    this.resumeSounds();
  };
}

export default SoundManager;

export { type TSoundControlState };
