import { BitmapText, Container, Graphics } from 'pixi.js';
import { Dede } from '.';
import { IFreeSpinStatus } from '../types';
import accamaxDebug from '../../debug';
import { Spine } from '@pixi/spine-pixi';
import { simpleAnimatePropertiesTo } from '../../game/managers/animationManager';
import { performCurrencyCountUp } from '../../game/managers/animationManager/animations';
import GameEvent, { IEventDetails } from '../gameEvent';
import { registerLogCategory } from '../../debug/privateLogger';
import SoundBase from '../../game/managers/soundManager/soundBase';

const log = registerLogCategory('BigWinManager');

const bigWinReelSpineScaleFactor = 0.00016;
const bigWinReelXOffsetRatio = 0.5;
const bigWinReelYOffsetRatio = 0.25;
const bigWinFadeDuration = 500;
const bigWinValueMaxWidth = 1000;
const bigWinValueFontSize = 112;
const bigWinValueOffsetY = 328;

const winSizeIndex = {
  big: 0,
  mega: 1,
  olympus: 2,
};

export class BigWinManager {
  freeSpinStatus?: IFreeSpinStatus;
  private _bigWinContainer?: Container;
  private _bigWinValueText!: BitmapText;
  private _spine?: Spine;
  private _modalMask!: Graphics;
  private _isEndingSequence = false;
  private _isActive = false;
  private _waitingForEndAnimation = false;
  private _endAnimationStarted = false;
  private _resolve!: () => void;
  private _prefix!: string;
  private _skipCountUp!: () => void;
  private _endSound!: SoundBase;
  private _timeout!: NodeJS.Timeout;

  constructor(private game: Dede) {
    game.onSpinComplete.addEventListener(this._handleSpinComplete);

    accamaxDebug.debug.triggerBigWin = (amount = 150) => {
      const winType = this._getWinType(amount);
      if (!winType) {
        console.error(
          `${amount} is not a big win amount.  Please make sure you have the correct iframe selected`
        );
        return;
      }
      this._mountPopup(amount, this._getWinType(amount) as 'big' | 'mega' | 'olympus');
    };

    game.onFreeSpinUpdated.addEventListener((event, status) => {
      this.freeSpinStatus = status;
    });
  }

  get isActive() {
    return this._isActive;
  }

  private _handleSpinComplete = (event: IEventDetails, value: { tumbleWinAmount: number }) => {
    const winType = this._getWinType(value.tumbleWinAmount);
    if (winType) {
      GameEvent.mainQueue.add(() => this._mountPopup(value.tumbleWinAmount, winType), 5);
    }
  };

  private _addListeners() {
    window.addEventListener('click', this._handlePointerDown);
    this.game.onSpacePressed.addEventListener(this._handlePointerDown);
    this.game.onResize.addEventListener(this._handleResize);
  }

  private _removeListeners() {
    window.removeEventListener('click', this._handlePointerDown);
    this.game.onSpacePressed.removeEventListener(this._handlePointerDown);
    this.game.onResize.removeEventListener(this._handleResize);
  }

  private _startBigWinEndSequence = () => {
    if (this._isEndingSequence) return;
    this._isEndingSequence = true;

    this._endSound = this.game.soundManager.bigWinTrack.transitionToBigWinEnd()!;

    clearTimeout(this._timeout);
    this._timeout = setTimeout(() => {
      this._waitingForEndAnimation = true;
    }, this._endSound!.duration);
  };

  private _handlePointerDown = () => {
    if (this._endAnimationStarted) return;
    clearTimeout(this._timeout);
    if (this._isEndingSequence) {
      this._endSound?.stop();
      this._bigWinContainer!.visible = false;
      this._waitingForEndAnimation = true;
      this._handleSkipToEnd(true);
    }

    this._skipCountUp?.();
    this._startBigWinEndSequence();
  };

  private _getWinType(winAmount: number) {
    const winSizes = this.game.config?.limits.winSizes;
    if (!winSizes) return null;

    if (winAmount >= this.game.stake * winSizes[winSizeIndex.olympus]) return 'olympus';
    if (winAmount >= this.game.stake * winSizes[winSizeIndex.mega]) return 'mega';
    if (winAmount >= this.game.stake * winSizes[winSizeIndex.big]) return 'big';

    return null;
  }

  private _handleSkipToEnd = async (skipAnimation?: boolean) => {
    if (this._waitingForEndAnimation && !this._endAnimationStarted) {
      this._endAnimationStarted = true;
      if (!skipAnimation) {
        const idleAnimation = this._spine!.state.setAnimation(0, `win${this._prefix}_end`, false);
        idleAnimation.listener = {
          complete: () => {
            requestAnimationFrame(() => {
              this._unmountPopup();
            });
          },
        };
      }
      await this._performFadeOut();
      if (skipAnimation) {
        this._unmountPopup();
      }
    }
  };

  _mountPopup(winAmount: number, winType: 'big' | 'mega' | 'olympus') {
    log(1)('mountPopup', winAmount, winType);

    this._isActive = true;
    this._waitingForEndAnimation = false;
    this._endAnimationStarted = false;
    if (winType === 'mega') {
      this.game.coinFountainManager.animate({ container: this._bigWinContainer, zIndex: 39 });
    } else if (winType === 'olympus') {
      this.game.coinFountainManager.animate({
        container: this._bigWinContainer,
        zIndex: 39,
        count: 2,
        randomSize: true,
      });
    }

    return new Promise<void>((resolve) => {
      const startDuration = this.game.soundManager.bigWinTrack.getBigWinDuration(winType);
      const endDuration = this.game.soundManager.bigWinTrack.getBigWinEndDuration(winType);
      this._resolve = resolve;
      this.game.betButtonsDisabler.disable('bigWin');
      this.game.gameDisabler.disable('bigWin');
      this._bigWinContainer = new Container();
      this.game.app.stage.addChild(this._bigWinContainer);
      this._bigWinContainer.zIndex = 40;
      this._prefix = winType === 'big' ? 'B' : winType === 'mega' ? 'M' : 'O';

      this._spine = Spine.from({ skeleton: `winData`, atlas: `winAtlas` });

      const animation = this._spine.state.setAnimation(0, `win${this._prefix}_start`, false);
      animation.listener = {
        complete: () => {
          const idleAnimation = this._spine!.state.setAnimation(0, `win${this._prefix}_idle`, true);
          idleAnimation.listener = {
            event: (entry, event) => {
              if (event.data.name === 'skip_to_end') {
                this._handleSkipToEnd();
              }
            },
          };
        },
      };

      this._bigWinContainer.addChild(this._spine);

      const modalMask = new Graphics();
      this.game.app.stage.addChild(modalMask);
      modalMask.rect(0, 0, this.game.app.screen.width, this.game.app.screen.height);
      modalMask.fill(0x000000);
      modalMask.alpha = 0;
      modalMask.zIndex = 39;
      this._modalMask = modalMask;

      this._bigWinValueText = new BitmapText({
        style: {
          fontFamily: 'goldenTextFontOld',
          fontSize: bigWinValueFontSize * 1.1,
          letterSpacing: -10,
        },
      });

      this._bigWinContainer.addChild(this._bigWinValueText);
      this._bigWinValueText.anchor.set(0.5);
      this._bigWinValueText.y = bigWinValueOffsetY;

      // Count up the currency
      const { skipToEnd } = 
      performCurrencyCountUp(
        this._bigWinValueText,
        startDuration + endDuration - 2000,
        0,
        winAmount,
        bigWinValueMaxWidth
      );
      this._skipCountUp = skipToEnd;

      // Fade the mask in
      simpleAnimatePropertiesTo(bigWinFadeDuration * 2, this._modalMask, this._modalMask, {
        alpha: { endValue: 0.75 },
      });
      this._isEndingSequence = false;
      this.game.soundManager.bigWinTrack.playBigWin(winType);
      const bigWinDuration = this.game.soundManager.bigWinTrack.getBigWinDuration(winType);

      clearTimeout(this._timeout);
      this._timeout = setTimeout(this._startBigWinEndSequence, bigWinDuration - 500);

      this._handleResize();
      this._addListeners();
    });
  }

  private _performFadeOut = () => {
    this.game.coinFountainManager.stop();

    simpleAnimatePropertiesTo(
      bigWinFadeDuration,
      this._modalMask,
      this._modalMask,
      { alpha: { endValue: 0 } },
      { autoEndOnError: true }
    );

    return simpleAnimatePropertiesTo(
      bigWinFadeDuration,
      this._bigWinValueText!,
      this._bigWinValueText!,
      { alpha: { endValue: 0 } },
      { autoEndOnError: true }
    ).promise;
  };

  private _unmountPopup() {
    this.game.gameDisabler.enable('bigWin');
    this.game.betButtonsDisabler.enable('bigWin');
    this._isEndingSequence = false;
    this._bigWinContainer!.parent.removeChild(this._bigWinContainer!);
    this._bigWinContainer!.destroy();
    this._bigWinContainer = undefined;
    this._modalMask.parent.removeChild(this._bigWinContainer!);
    this._modalMask.destroy();
    this._isActive = false;
    this._removeListeners();
    this.game.onBigWinAnimationCompleted.triggerEvent();
    this._resolve();
  }

  private _handleResize = () => {
    if (!this._bigWinContainer) return;

    const globalDimensions = this.game.reelsManager.containerSprite.getBounds();

    this._modalMask!.width = this.game.app.screen.width;
    this._modalMask!.height = this.game.app.screen.height;

    this._bigWinContainer!.x = globalDimensions.x + globalDimensions.width * bigWinReelXOffsetRatio;
    this._bigWinContainer!.y =
      globalDimensions.y + globalDimensions.height * bigWinReelYOffsetRatio;
    this._bigWinContainer!.scale = globalDimensions.width * bigWinReelSpineScaleFactor;
  };
}
