import { Container, Graphics, Sprite, Texture } from 'pixi.js';
import Button from '../button';
import {
  BLOCK_CONTAINER_LANDSCAPE_X_OFFSET,
  BLOCK_CONTAINER_LANDSCAPE_Y_OFFSET,
  BLOCK_CONTAINER_X_OFFSET,
  BLOCK_CONTAINER_Y_OFFSET,
  CONTAINER_PORTRAIT_X_OFFSET,
  CONTAINER_PORTRAIT_Y_OFFSET,
  CONTAINER_Y_OFFSET,
  PORTRAIT_X_OFFSET,
  PORTRAIT_Y_OFFSET,
  TEXT_BUTTONS_CONTAINER_LANDSCAPE_X_OFFSET,
  TEXT_BUTTONS_CONTAINER_LANDSCAPE_Y_OFFSET,
  TEXT_BUTTONS_CONTAINER_PORTRAIT_X_OFFSET,
  TEXT_BUTTONS_CONTAINER_PORTRAIT_Y_OFFSET,
} from './constants';
import { mountPortraitButtons } from './portraitButtons';
import { mountLandscapeButtons } from './landscapeButtons';
import { Orientation } from '../../../types';
import { REELS_MANAGER_RIGHT_END, SHORT_DIMENSION } from '../../resources/constants';
import { Dede } from '../..';
import { mountCommonButtons } from './commonButtons';
import TextButton from '../textButton';

export default class GameController {
  infoSoundContainer = new Container();
  textButtonsContainer = new Graphics();
  blockContainer = new Graphics();
  width = 0;
  height = 0;

  buyBonusButton!: TextButton;
  goldenBetButton!: TextButton;
  subBetButton!: Button;
  settingsButton!: Button;
  soundButton!: Button;
  addBetButton!: Button;
  turboSpinButton!: Button;
  stakeButton!: Button;
  spinButton!: Button;
  infoButton!: Button;
  autoPlayButton!: Button;
  spinContainer!: Sprite;
  orientation!: Orientation;

  constructor(public game: Dede) {
    this.infoSoundContainer.zIndex = 5;
    this.blockContainer.zIndex = 5;
    // this.blockContainer.anchor.set(0.5, 0);
    this.textButtonsContainer.zIndex = 5;
    this.game.onFreeSpinUpdated.addEventListener((e, freeSpins) => {
      if (game.freeSpinActivated) {
        this.textButtonsContainer.visible = false;
      } else {
        this.textButtonsContainer.visible = true;
      }
    });
  }

  handleResize = () => {
    const orientation = this.game.getOrientation();

    let scale = this.game.scale;
    this.blockContainer.scale.set(scale);

    if (orientation === 'portrait') {
      this.infoSoundContainer.x =
        this.game.reelsManager.container.x + CONTAINER_PORTRAIT_X_OFFSET * scale;
      this.infoSoundContainer.y =
        this.game.height - (this.infoSoundContainer.height + CONTAINER_PORTRAIT_Y_OFFSET * scale);

      this.textButtonsContainer.x =
        this.game.reelsManager.container.x + TEXT_BUTTONS_CONTAINER_PORTRAIT_X_OFFSET * scale;
      this.textButtonsContainer.y =
        this.game.height - this.textButtonsContainer.height - 100 * scale;

      this.blockContainer.x = (this.game.width - this.blockContainer.width) / 2;
      this.blockContainer.y = this.game.height - this.textButtonsContainer.height - 100 * scale;
    } else {
      this.infoSoundContainer.x = (this.game.width - this.game.reelsManager.container.width) / 2;
      this.infoSoundContainer.y =
        this.game.reelsManager.container.y +
        this.game.reelsManager.container.height +
        CONTAINER_Y_OFFSET * scale;

      this.textButtonsContainer.x =
        this.game.dynamicGameContainerRightEndX +
        (this.game.width - this.game.dynamicGameContainerRightEndX) / 2;

      this.textButtonsContainer.y =
        this.game.reelsManager.container.y +
        this.game.reelsManager.container.height +
        TEXT_BUTTONS_CONTAINER_LANDSCAPE_Y_OFFSET * scale;

      this.blockContainer.x =
        this.game.dynamicGameContainerRightEndX +
        (this.game.width - this.game.dynamicGameContainerRightEndX) / 2 -
        this.blockContainer.width / 2;

      this.blockContainer.y =
        this.game.reelsManager.container.y +
        this.game.reelsManager.container.height +
        BLOCK_CONTAINER_LANDSCAPE_Y_OFFSET * scale;
    }
    if (orientation !== this.orientation) {
      this.mountButtons();
      this.orientation = orientation;
    }
    this.infoSoundContainer.scale.set(scale);
    this.textButtonsContainer.scale.set(scale);
  };

  destroyButtons() {
    this.buyBonusButton?.destroy();
    this.goldenBetButton?.destroy();
    this.subBetButton?.destroy();
    this.addBetButton?.destroy();
    this.turboSpinButton?.destroy();
    this.stakeButton?.destroy();
    this.spinButton?.destroy();
    this.infoButton?.destroy();
    this.autoPlayButton?.destroy();
    this.spinContainer?.destroy();
    this.soundButton?.destroy();
    this.settingsButton?.destroy();
  }

  mountButtons() {
    this.destroyButtons();
    const orientation = this.game.getOrientation();
    mountCommonButtons(this);
    if (orientation === 'portrait') {
      mountPortraitButtons(this);
    } else {
      mountLandscapeButtons(this);
    }
    this.refreshButtonsStatuses();
  }

  refreshButtonsStatuses = () => {
    const disabled = this.game.betButtonsDisabler.disabled;
    this.spinButton.changeTexture(disabled ? 'spinDisabled' : 'spin');
    this.spinButton.rotateOnPress = !disabled;
    this.stakeButton.disabled = disabled;
    this.subBetButton.disabled = disabled;
    this.addBetButton.disabled = disabled;
    // this.turboSpinButton.disabled = !value;
    this.goldenBetButton.disabled = disabled;
    if (!this.game.anteBetActive) this.buyBonusButton.disabled = disabled;
    // this.textButtonsContainer.alpha = !value ? 0.5 : 1;

    if (this.game.autoPlayCount === 0) {
      this.autoPlayButton.disabled = disabled;
    }

    if (this.game.anteBetActive) this.buyBonusButton.disabled = true;
  };

  mount() {
    this.game.app.stage.addChild(this.infoSoundContainer);
    this.game.app.stage.addChild(this.textButtonsContainer);
    this.game.app.stage.addChild(this.blockContainer);

    this.infoSoundContainer.scale.set(this.game.reelsManager.scale);
    // this.blockContainer.scale.set(this.game.reelsManager.scale);

    this.orientation = this.game.getOrientation();
    this.mountButtons();

    this.game.onBetButtonsEnabledChange.addEventListener(this.refreshButtonsStatuses);
    this.game.onAnteBetChange.addEventListener(this.refreshButtonsStatuses);

    this.game.onSpinComplete.addEventListener(() => {
      if (this.game.finishInstantlyActive) {
        this.game.finishInstantlyActive = false;
      }
    });

    this.game.onFreeSpinUpdated.addEventListener((e, freeSpinData) => {
      if (freeSpinData.isActivating || freeSpinData.activated) {
        this.textButtonsContainer.visible = false;
        this.blockContainer.alpha = 0;
      } else {
        this.textButtonsContainer.visible = true;
        this.blockContainer.alpha = 1;
      }
    });

    this.handleResize();
    this.game.onResize.addEventListener(this.handleResize, -3);
  }
}
