import { Spine } from '@pixi/spine-pixi';
import { Game } from '../../../games/game';
import { Container } from 'pixi.js';
import delay from 'delay';
import { simpleAnimatePropertiesTo } from '../animationManager';

export class CoinFountainManager {
  spines: Spine[] = [];
  zIndex = 9999;
  container!: Container;
  isRain = false;

  constructor(private game: Game) {
    this.container = game.app.stage;
  }

  handleResize = () => {
    this.spines.forEach((spine) => {
      if (!spine) return;
      spine.x = this.game.width / 2;
      spine.y = this.game.height / 2 + (this.isRain ? -200 : 0);
      if (this.isRain) spine.scale.set(this.game.scale * 1);
      else spine.scale.set(this.game.scale * 0.7);
      spine.zIndex = this.zIndex;
    });
  };

  stop = async () => {
    const promises = [];
    for (let i = 0; i < this.spines.length; i++) {
      const spine = this.spines[i];
      promises.push(
        simpleAnimatePropertiesTo(
          500,
          spine,
          spine,
          { alpha: { endValue: 0 } },
          { autoEndOnError: true }
        ).promise
      );
    }
    await Promise.all(promises);
    this.spines.forEach((el) => el.destroy());
    this.spines = [];
    this.game.onResize.removeEventListener(this.handleResize);
  };

  async animate(options?: {
    container?: Container;
    zIndex: number;
    count?: number;
    randomSize?: boolean;
    randomSpeed?: boolean;
    isRain?: boolean;
  }) {
    await this.stop();
    const count = options?.count || 1;
    this.container = options?.container || this.container;
    this.zIndex = options?.zIndex ?? 9999;
    this.isRain = options?.isRain || false;

    for (let i = 0; i < count; i++) {
      const spine = Spine.from({
        skeleton: `coinFountainData`,
        atlas: `coinFountainAtlas`,
      });
      spine.zIndex = this.zIndex;
      spine.visible = false;
      // randomSize scale 0.7 - 1
      if (options?.randomSize) {
        const scale = Math.random() * 0.4 + 0.6;
        spine.scale.set(this.game.scale * scale);
      }
      // randomSpeed
      if (options?.randomSpeed) {
        spine.state.timeScale = Math.random() * 0.4 + 0.8;
      }
      this.container.addChild(spine);
      this.spines.push(spine);
    }

    setTimeout(async () => {
      for (let i = 0; i < count; i++) {
        const spine = this.spines[i];
        if (!spine) return;
        spine.visible = true;
        const animation = spine.state.setAnimation(
          0,
          this.isRain ? 'coin_r_start' : 'coin_f_start',
          false
        );
        animation.listener = {
          complete: () => {
            setTimeout(() => {
              spine.state.setAnimation(0, this.isRain ? 'coin_r_loop' : 'coin_f_loop', true);
            }, 0);
          },
        };
        await delay(150);
      }
    }, 0);

    this.game.onResize.addEventListener(this.handleResize);
    this.handleResize();
  }
}
