import { Sprite, Texture } from 'pixi.js';
import GameController from '.';
import Button from '../button';
import {
  PORTRAIT_INFO_BUTTON_CONTENT_X,
  PORTRAIT_INFO_BUTTON_CONTENT_Y,
  PORTRAIT_INFO_BUTTON_X,
  PORTRAIT_INFO_BUTTON_Y,
  SMALL_BUTTON_WIDTH,
  SMALL_BUTTON_HEIGHT,
  PORTRAIT_SOUND_BUTTON_X,
  PORTRAIT_SOUND_BUTTON_Y,
  PORTRAIT_SETTINGS_BUTTON_X,
  PORTRAIT_SETTINGS_BUTTON_Y,
} from './constants';

import CachedSettingsManager from '../../../../cachedSettingsManager';
import { TSoundControlState } from '../../managers/soundManager';

export const mountPortraitButtons = (controller: GameController) => {
  controller.settingsButton = new Button(controller.infoSoundContainer, {
    backgroundTexture: 'config',
    onClick: () => {
      controller.game.onClick.triggerEvent();
      controller.game.onSettingsButtonClick.triggerEvent();
    },
    backgroundPosition: {
      x: PORTRAIT_SETTINGS_BUTTON_X,
      y: PORTRAIT_SETTINGS_BUTTON_Y,
      width: SMALL_BUTTON_WIDTH,
      height: SMALL_BUTTON_HEIGHT,
    },
    contentPosition: {
      x: 0,
      y: 0,
    },
  });

  const _soundState = CachedSettingsManager.get('soundControlState') as TSoundControlState;
  controller.soundButton = new Button(controller.infoSoundContainer, {
    backgroundTexture: _soundState.gameSoundEnabled ? 'sound' : 'soundDisabled',
    onClick: () => {
      controller.game.onClick.triggerEvent();
      const soundState = CachedSettingsManager.get('soundControlState') as TSoundControlState;
      const prevValue = soundState.gameSoundEnabled;

      soundState.gameSoundEnabled = !soundState.gameSoundEnabled;
      if (
        soundState.soundEffectsEnabled === soundState.musicEnabled &&
        soundState.musicEnabled === prevValue
      ) {
        soundState.musicEnabled = soundState.gameSoundEnabled;
        soundState.soundEffectsEnabled = soundState.gameSoundEnabled;
      }

      CachedSettingsManager.set('soundControlState', { ...soundState });

      controller.soundButton.background.texture = Texture.from(
        soundState.gameSoundEnabled ? 'sound' : 'soundDisabled'
      );
    },
    backgroundPosition: {
      x: PORTRAIT_SOUND_BUTTON_X,
      y: PORTRAIT_SOUND_BUTTON_Y,
      width: SMALL_BUTTON_WIDTH,
      height: SMALL_BUTTON_HEIGHT,
    },
    contentPosition: {
      x: 0,
      y: 0,
    },
  });

  CachedSettingsManager.addListener('soundControlState', (value) => {
    const soundState = value as TSoundControlState;
    controller.soundButton.background.texture = Texture.from(
      soundState.gameSoundEnabled ? 'sound' : 'soundDisabled'
    );
  });

  controller.infoButton = new Button(controller.infoSoundContainer, {
    backgroundTexture: 'info',
    onClick: () => {
      controller.game.onClick.triggerEvent();
      controller.game.onInfoButtonClick.triggerEvent();
    },
    backgroundPosition: {
      x: PORTRAIT_INFO_BUTTON_X,
      y: PORTRAIT_INFO_BUTTON_Y,
      width: SMALL_BUTTON_WIDTH,
      height: SMALL_BUTTON_HEIGHT,
    },
    contentPosition: {
      x: PORTRAIT_INFO_BUTTON_CONTENT_X,
      y: PORTRAIT_INFO_BUTTON_CONTENT_Y,
    },
  });
};
