export default class Disabler {
  private _disabled = false;
  private _disableKeys: string[] = [];
  private _disableTimeouts: { timeout: NodeJS.Timeout; key: string }[] = [];

  constructor(private onChange: (disabled: boolean) => void = () => {}) {}

  get disabled() {
    return this._disabled;
  }

  disable(key: string, enableTimeout?: number) {
    if (this._disableKeys.includes(key)) return;
    this._disableKeys.push(key);
    this._disabled = true;
    this.onChange(this._disabled);
    if (enableTimeout) {
      const timeout = setTimeout(() => {
        this.enable(key, false);
      }, enableTimeout);
      this._disableTimeouts.push({ timeout, key });
    }
  }

  enable(key: string, force = false) {
    const index = this._disableKeys.indexOf(key);
    if (index === -1) return;
    this._disableKeys.splice(index, 1);
    if (this._disableKeys.length === 0 || force) {
      this._disabled = false;
      this.onChange(this._disabled);
    }

    this._disableTimeouts.forEach(({ timeout, key }) => {
      if (key === key || force) {
        clearTimeout(timeout);
      }
    });
    this._disableTimeouts = this._disableTimeouts.filter(({ key }) => key !== key || force);
  }

  enableAll() {
    this._disableKeys = [];
    this._disabled = false;
    this._disableTimeouts.forEach(({ timeout }) => clearTimeout(timeout));
    this._disableTimeouts = [];
    this.onChange(this._disabled);
  }
}
